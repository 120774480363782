import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  ApproveDeathModificationApi,
  QueryDeathModficationApi,
  getSingleDeathModification,
} from "../../redux/apiCalls";
import { Button } from "primereact/button";
import {
  modificationStatus,
  getDetailsForModificationType,
  getModBgcolor,
} from "../../utils";
import ApprovalActions from "./ApprovalActions";
import RecordDetails from "./recordDetails";

const ViewSingleDeathModification = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [records, setRecords] = useState([]);
  const [color, setColor] = useState("");

  const getSingleRecord = async () => {
    setLoading(true);
    try {
      const response = await getSingleDeathModification(id);
      setLoading(false);
      setRecords(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    getSingleRecord();
  }, [id, approveSuccess]);

  useEffect(() => {
    const color = getModBgcolor(+records?.mod_status);
    setColor(color);
  }, [records, approveSuccess]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="flex flex-column gap-5">
        <article className={`${color} text-white text-sm p-2`}>
          <span className="text-sm font-bold">Modification Details</span>
        </article>

        <RecordDetails
          type="Applicant"
          records={records}
          fieldsToDisplay={["surname", "first_name", "middle_name"]}
          fieldProcessors={{
            surname: (records) =>
              `${records?.Death?.deceasedData?.surname ?? "----"}`,
            first_name: (records) =>
              `${records?.Death?.deceasedData?.firstname ?? "----"}
`,
            middle_name: (records) =>
              `${records?.Death?.deceasedData?.middle_name ?? "----"}`,
          }}
        />
        <article className="shadow-lg bg-white text-sm px-4 py-2 box-shadow-css">
          <section style={{ wordBreak: "break-word" }}>
            <div className="grid py-3">
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span>Modification Type</span>
                <span className="font-semibold uppercase">
                  {records?.mod_type?.replace(/_/g, " ") ?? "----"}
                </span>
              </div>
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span>Field Changed</span>
                <span className="font-semibold uppercase">
                  {records?.mod_field?.replace(/_/g, " ") ?? "----"}
                </span>
              </div>
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span>Reasons for modification</span>
                <span className="font-semibold uppercase">
                  {records?.mod_reason ?? "----"}
                </span>
              </div>
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span>Comments</span>
                <span className="font-semibold uppercase">
                  {records?.comment ?? "----"}
                </span>
              </div>
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span>Previous Record</span>
                <span className="font-semibold uppercase">
                  {
                    getDetailsForModificationType(records?.mod_field, records)
                      .OldDetails
                  }
                </span>
              </div>
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span>Modified Record</span>
                <span className="font-semibold uppercase">
                  {
                    getDetailsForModificationType(records?.mod_field, records)
                      .newDetails
                  }
                </span>
              </div>
            </div>
          </section>
        </article>
      </div>
      <ApprovalActions
        id={id}
        records={records}
        setApproveSuccess={setApproveSuccess}
        approveSuccess={approveSuccess}
        approveApi={ApproveDeathModificationApi}
        queryApi={QueryDeathModficationApi}
        ApprovalStatus={+records?.mod_status}
        approvalStatusType={modificationStatus}
      />
    </div>
  );
};

export default ViewSingleDeathModification;
