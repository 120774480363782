export const RegTypeOptions = Object.freeze({
  Assisted: 0,
  SelfService: 1,
});
export const Status = Object.freeze({
  Active: "Active",
  InAvtive: "Inactive",
});
export const OrganizationVerificationStatus = Object.freeze({
  approved: "approved",
  rejected: "rejected",
  pending: "pending",
});
export const OrganizationVerificationLevels = Object.freeze({
  level1: "level1",
  level2: "level2",
  level3: "level3",
  level4: "level4",
  level5: "level5",
});

export const Permissions = Object.freeze({
  // Birth Registration Module
  canSeeBirth: "Can_See_Birth",
  canListBirth: "Can_List_Birth",
  canViewBirth: "Can_View_Birth",
  canRegisterBirth: "Can_Register_Birth",
  canModifyBirth: "Can_Modify_Birth",
  canQueryBirth: "Can_Query_Birth",
  canApproveBirth: "Can_Approve_Birth",

  // Attesttation Module
  canSeeAattestation: "Can_See_Attestation",
  canListAattestation: "Can_List_Attestation",
  canViewAattestation: "Can_View_Attestation",
  canDoAattestation: "Can_Do_Attestation",
  canModifyAattestation: "Can_Modify_Attestation",
  canQuerAattestation: "Can_Query_Attestation",
  canApproveAattestation: "Can_Approve_Attestation",

  // Death Module
  canSeeDeath: "Can_See_Death",
  canListDeath: "Can_List_Death",
  canViewDeath: "Can_View_Death",
  canRegisterDeath: "Can_Register_Death",
  canModifyDeath: "Can_Modify_Death",
  canQueryDeath: "Can_Query_Death",
  canApproveDeath: "Can_Approve_Death",

  // Stillbirth Module
  canSeeStillbirth: "Can_See_Stillbirth",
  canListStillbirth: "Can_List_Stillbirth",
  canViewStillbirth: "Can_View_Stillbirth",
  canRegisterStillbirth: "Can_Register_Stillbirth",
  canModifyStillbirth: "Can_Modify_Stillbirth",
  canQueryStillbirth: "Can_Query_Stillbirth",
  canApproveStillbirth: "Can_Approve_Stillbirth",

  // Modification Module
  canSeeModification: "Can_See_Modification",
  canListModification: "Can_List_Modification",
  canViewModification: "Can_View_Modification",
  canQueryModification: "Can_Query_Modification",
  canApproveModification: "Can_Approve_Modification",

  // user management
  canSeeUser: "Can_See_Users",
  canManageUser: "Can_Manage_Users",
  canManageStateDirector: "Can_Manage_State_Directors",
  canManageRegistrar: "Can_Manage_Registrars",
  canManageDCR: "Can_Manage_DCRs",
  canManageAdhocs: "Can_Manage_Adhocs",

  // Hospital management
  canSeeHospital: "Can_See_Hospitals",
  canListHospital: "Can_List_Hospitals",
  canViewHospital: "Can_View_Hospitals",
  canResetHospitalPassword: "Can_Reset_Hospitals_Password",
  canApproveHospital: "Can_Approve_Hospitals",
  canRejectHospital: "Can_Reject_Hospitals",
  canDeactivateHospital: "Can_Deactivate_Hospitals",
  canActivateHospital: "Can_Activate_Hospitals",

  // Verification Report
  canSeeVerification: "Can_See_Verification",

  // Transaction Report
  canSeeTransactionReport: "Can_See_Transaction_Report",
  canListTransactionReport: "Can_List_Transaction_Report",
  canViewTransactionReport: "Can_View_Transaction_Report",
  canViewTransactionReportCards: "Can_View_Transaction_Report_Cards",
  canRefundTransaction: "Can_Refund_Transaction",

  // Approval TIU
  canSeeBirthApproval: "Can_See_Birth_Approval",
  canSeeBirthModificationApproval: "Can_See_Birth_Modification_Approval",
  canSeeAttestationApproval: "Can_See_Attestation_Approval",
  canSeeAttestationModificationApproval:
    "Can_See_Attestation_Modification_Approval",
  canSeeDeathApproval: "Can_See_Death_Approval",
  canSeeDeathModificationApproval: "Can_See_Death_Modification_Approval",

  //Notifications
  canSeeBirthNotification: "Can_See_Birth_Notification",
  canListBirthNotification: "Can_List_Birth_Notification",
  canViewBirthNotification: "Can_View_Birth_Notification",

  canSeeAttestationWithNoNIN: "Can_See_Attestation_With_No_NIN",
  canListAttestationWithNoNIN: "Can_List_Attestation_With_No_NIN",
  canViewAttestationWithNoNIN: "Can_View_Attestation_With_No_NIN",
});
export const TIUPermissions = [
  "Can_List_Birth",
  "Can_View_Birth",
  "Can_Query_Birth",
  "Can_Approve_Birth",
  "Can_List_Attestation",
  "Can_View_Attestation",
  "Can_Query_Attestation",
  "Can_Approve_Attestation",
  "Can_List_Death",
  "Can_View_Death",
  "Can_Query_Death",
  "Can_Approve_Death",
  "Can_List_Modification",
  "Can_View_Modification",
  "Can_Query_Modification",
  "Can_Approve_Modification",
  "Can_See_Birth_Approval",
  "Can_See_Birth_Modification_Approval",
  "Can_See_Attestation_Approval",
  "Can_See_Attestation_Modification_Approval",
  "Can_See_Death_Approval",
  "Can_See_Death_Modification_Approval",
  "Can_See_Birth_Notification",
  "Can_List_Birth_Notification",
  "Can_View_Birth_Notification",
  "Can_See_Attestation_With_No_NIN",
  "Can_List_Attestation_With_No_NIN",
  "Can_View_Attestation_With_No_NIN",
];

export const Roles = Object.freeze({
  StateDirector: 1,
  DCR: 2,
  Registrar: 3,
  Adhoc: 4,
  Admin: 5,
  APIAuth: 6,
  Hospital: 7,
  SubAdmin: 8,
  TIU: 9,
});

export const organizations = {
  Private: "Private",
  Government: "Government",
  NonGovernment: "NGO",
  Embassy: "Embassy",
  NonProfit: "NonProfit",
  International: "International",
};

export const roleOptions = [
  {
    name: "Admin",
    id: Roles.Admin,
  },
  {
    name: "Sub Admin",
    id: Roles.SubAdmin,
  },
  {
    name: "Registrars",
    id: Roles.Registrar,
  },
  {
    name: "DCR",
    id: Roles.DCR,
  },
  {
    name: "State Director",
    id: Roles.StateDirector,
  },
  {
    name: "ADHOC",
    id: Roles.Adhoc,
  },
  {
    name: "TIU",
    id: Roles.TIU,
  },
];

export const statusOptions = [
  {
    name: "Active",
    id: Status.Active,
  },
  {
    name: "Inactive",
    id: Status.InAvtive,
  },
];

export const CREATE_USER_ERROR_MESSAGES = {
  nin: "NIN is required",
  phone: "Phone is required",
  username: "Username is required",
  email: "Email is required",
  state: "State is required",
  lga: "Lga is Required",
  center: "Center is required",
  role: "Role is required",
  status: "Status is required",
};

export const sexType = [
  { value: "1", name: "Male" },
  { value: "2", name: "Female" },
];

export const modField = {
  child_name: "child_name",
  father_name: "father_name",
  mother_name: "mother_name",
  child_sex: "child_sex",
  deceased_name: "deceased_name",
  deceased_sex: "deceased_sex",
  deceased_age_at_death: "deceased_age_at_death",
  deceased_date_of_death: "deceased_date_of_death",
  applicant_name: "applicant_name",
  applicant_sex: "applicant_sex",
  applicant_town: "applicant_town",
  applicant_face: "face",
};

export const statusFilterOptions = [
  {
    name: "All",
    id: "",
  },

  {
    name: "Pending",
    id: "pending",
  },
  {
    name: "Approved",
    id: "approved",
  },
  {
    name: "Queried",
    id: "queried",
  },
];
export const verificationStatusFilterOptions = [
  {
    name: "All",
    id: null,
  },
  {
    name: "Pending",
    id: "pending",
  },
  {
    name: "Approved",
    id: "approved",
  },
  {
    name: "Rejected",
    id: "rejected",
  },
];
export const selfFilterOptions = [
  {
    name: "All",
    id: "",
  },
  {
    name: "Self Service",
    id: "yes",
  },
  {
    name: "Assisted",
    id: "no",
  },
];
