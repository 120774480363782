import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ProgressSpinner } from "primereact/progressspinner";

const PermissionAccess = ({ allowedPermissions, children }) => {
  const { userInfo } = useSelector((state) => state.user);
  const location = useLocation();
  const permissionsArray = userInfo?.permission?.permissions.split(",");
  const hasAccess = permissionsArray?.some((permission) =>
    allowedPermissions.includes(permission)
  );

  if (!userInfo) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  return hasAccess ? (
    children
  ) : (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  );
};

export default PermissionAccess;
