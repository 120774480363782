import { Button } from "primereact/button";
import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import { useSearchParams } from "react-router-dom";
import {
  getAllUsersUnderRoleApi,
  getSingleUserApi,
} from "../../redux/apiCalls";
import { Roles } from "../../helpers/constants";
import { getChildRoleNameByRoleId } from "../../utils";
import { Paginator } from "primereact/paginator";
import { ProgressSpinner } from "primereact/progressspinner";
const ViewUser = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const lga = searchParams.get("lga");
  const state = searchParams.get("state");
  const regCenter = searchParams.get("center");
  const [loading, setLoading] = useState(true);
  const [filters1, setFilters1] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [userData, setUserData] = useState(null);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(20);
  const [pagination, setPagination] = useState([]);
  const [records, setRecords] = useState([]);

  let pageIndexOffset = 0;
  const pageStartIndex = page > 1 ? (page - 1) * rows : 0;
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1); // PrimeReact uses 0-based page index
    setRows(event.rows);
    pageIndexOffset = event.first;
  };

  const SN = (rowData) => {
    const sn = records.findIndex((items, index) => items === rowData);
    return <p>{pageStartIndex + sn + 1 + pageIndexOffset}</p>;
  };

  useEffect(() => {
    const getSingleUser = async () => {
      const data = await getSingleUserApi(id);
      console.log(data);
      setUserData(data);
      setLoading(false);
    };

    getSingleUser();
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllUsers = async () => {
    setLoading(true);
    try {
      const data = await getAllUsersUnderRoleApi({
        result_per_page: rows,
        page,
        role_id: userData?.Role_ID,
        state_id: state || null,
        lga_id: lga || null,
        regcenter_id: regCenter || null,
      });

      console.log("allUsersUnder", data);
      setRecords(data.result);
      setPagination(data.pagination);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [state, id, regCenter, lga, userData]);

  useEffect(() => {
    initFilters1();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setGlobalFilterValue1("");
  };

  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    console.log(value);
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    console.log(_filters1);
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const clearFilter1 = () => {
    initFilters1();
  };
  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange1}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      {loading ? (
        <div className="flex justify-content-center align-items-center">
          <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
        </div>
      ) : (
        <>
          <div className="surface-card p-4 shadow-2 border-round my-2">
            <div className="flex flex-column md:flex-row md:flex-wrap pt-2 border-top-1 border-bottom-1 border-green-300">
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">UserName</div>
                <div className="text-900">{userData && userData.UserName}</div>
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-500 font-medium mb-2">Phone Number</div>
                <div className="text-900">{userData && userData.Phone_No}</div>
                {/* <div className="text-900">Elliot Alderson</div> */}
              </div>
              <div className="col-12 md:col-5 p-3">
                <div className="text-500 font-medium mb-2">Email Address</div>
                <div className="text-900">{userData && userData.Email}</div>
                {/* <div className="text-900">Elliot Alderson</div> */}
              </div>
              <div className="col-12 md:col-2 p-3">
                <div className="text-900">registrations</div>
                <div className="text-900">
                  {userData && userData.registrations}
                </div>
              </div>
            </div>
          </div>

          {[Roles.StateDirector, Roles.DCR, Roles.Registrar].includes(
            +userData?.Role_ID
          ) && (
            <div className="grid mt-2">
              <div className="col-12 lg:col-12">
                <div className="card border-round shadow-2 p-4 ">
                  <div className="mb-3 flex align-items-center justify-content-between p-3">
                    <span className="text-xl font-medium text-900">
                      All {getChildRoleNameByRoleId(+userData?.Role_ID)}
                    </span>
                  </div>
                  <DataTable
                    value={records}
                    ref={dt}
                    filters={filters1}
                    loading={loading}
                    stripedRows
                    responsiveLayout="stack"
                    header={header1}
                  >
                    <Column body={SN} header="S/N"></Column>
                    <Column field="UserName" header="Surname "></Column>
                    <Column field="Email" header="First Name "></Column>
                    <Column field="Phone_No" header="phone"></Column>
                  </DataTable>

                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={pagination?.total}
                    rowsPerPageOptions={[10, 20, 50, 100]}
                    onPageChange={onPageChange}
                  ></Paginator>
                  <Tooltip target=".export-buttons>button" position="bottom" />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ViewUser;
