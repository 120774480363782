import { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
// import { Dialog } from 'primereact/dialog';
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
// import jsPDF from 'jspdf';
import { Tooltip } from "primereact/tooltip";
import authFetch from "../axios";
import { Toast } from "primereact/toast";
import { Paginator } from "primereact/paginator";
import { SplitButton } from "primereact/splitbutton";
import Swal from "sweetalert2/dist/sweetalert2.js";
const DcrList = () => {
  // const {id}=useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  // eslint-disable-next-line
  const [userState, setUserState] = useState("");
  const [userLga, setUserLga] = useState("");
  // eslint-disable-next-line

  const [reload, setReload] = useState(false);
  const [state, setStates] = useState([]);
  const [loading1, setLoading1] = useState(true);
  const [lga, setLga] = useState([]);
  const [filters1, setFilters1] = useState(null);
  // eslint-disable-next-line
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  //   const [products, setProducts] = useState([]);
  const [tot, setTot] = useState(null);
  //   const [globalFilterValue1, setGlobalFilterValue1] = useState('');
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const changeState = async (e) => {
    setUserState(e.target.value);
    const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    setLga(statz.data);
  };

  const [basicFirst, setBasicFirst] = useState(0);
  // eslint-disable-next-line
  const [basicRows, setBasicRows] = useState(20);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setPage(event.page + 1);
  };

  const handleReset = async (id) => {
    // console.log(id)
    try {
      const statz = await authFetch.get(`/users/reset-password/${id}`);
      console.log(statz.data);
      if (statz.data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message},check your email`,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${statz.data.message}.`,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
    }
  };

  const handleActivate = async (id, st) => {
    let statuss = "";
    if (st === 1) {
      statuss = "Inactive";
    } else if (st === 0) {
      statuss = "Active";
    }
    try {
      const statz = await authFetch.post(`/users/change-status`, {
        account_id: parseInt(id),
        status: statuss,
      });
      // console.log(statz.data)
      if (statz.data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message}.`,
        });
        setReload(true);
        window.location.reload();
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${statz.data.message}.`,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
    }
  };
  useEffect(() => {
    const getDatz = async () => {
      setLoading1(true);
      const statz = await authFetch.get(
        `/users/?search=${search}&result_per_page=20&page=${page}&role_id=2&state_id=${userState}&lga_id=${userLga}`
      );
      console.log(statz.data.result);
      setProducts(statz.data.result);
      setTot(statz.data.pagination.total);
      setLoading1(false);
    };
    getDatz();
  }, [page, userLga, userState, search, reload]);
  // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getStates = async () => {
      const statz1 = await authFetch.get(`/option/states/63`);

      // option/permissions
      setStates(statz1.data);
    };
    // const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    // console.log(statz.data.result)
    // setMyStatz(statz.data.mtn);

    getStates();
  }, []);
  useEffect(() => {
    initFilters1();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    setGlobalFilterValue1("");
  };
  // eslint-disable-next-line
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    console.log(value);
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;
    console.log(_filters1);
    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  const statusBodyTemplate4 = (rowData) => {
    return (
      <p className={`btn btn-primary text-primary font-bold`}>
        {rowData.State?.State_Name ?? ""}
      </p>
    );
  };
  const statusBodyTemplate8 = (rowData) => {
    const items = [
      {
        label: "reset password",
        // icon: 'pi pi-refresh',
        command: () => {
          handleReset(rowData.User_ID);
        },
      },
      {
        label:
          rowData.is_active === 1
            ? "Deactivate"
            : rowData.is_active === 0
            ? "Activate"
            : "",
        // icon: 'pi pi-times',
        //   command: () => {
        //     handleActivate(rowData.User_ID,rowData.is_active)
        //   }
        command: () => {
          Swal.fire({
            title: "Confirm",
            text: `Are you sure you want to proceed?`,
            // text: `proceed`,
            // icon: 'success',
            // imageUrl:`data:image/jpeg;base64,${data.palliative.photo}`,
            // imageWidth:'100px',
            // imageHeight:'100px',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonColor: "#0b6916",
            confirmButtonText: "Proceed",
          }).then((result) => {
            if (result.value) {
              handleActivate(rowData.User_ID, rowData.is_active);
            }
          });
        },
      },
      {
        label: "unlink",
        // icon: 'pi pi-external-link',
        command: () => {
          handleUnlink(rowData.User_ID);
        },
      },
      {
        label: "Update user",
        command: () => {
          navigate(`/update-user/${rowData.User_ID}`);
        },
      },
      {
        label: "View user",
        command: () => {
          navigate(`/single-dcr?lga=${rowData.LGA_ID}&id=${rowData.User_ID}`);
        },
      },
    ];
    return (
      <SplitButton
        label="Actions"
        className="p-button-sm"
        model={items}
        // size="small"
      />
    );
  };
  // const statusBodyTemplate5 = (rowData) => {
  //     return <p  className={`btn btn-primary text-primary font-bold cursor-pointer`} onClick={()=>handleReset(rowData.User_ID)} >
  // reset password
  //     </p>
  // }

  // const statusBodyTemplate6 = (rowData) => {
  //     return <p  className={`btn btn-primary text-primary font-bold cursor-pointer`} onClick={()=>handleActivate(rowData.User_ID,rowData.is_active)} >
  // {rowData.is_active === 1?'Deactivate':rowData.is_active === 0?'Activate':''}
  //     </p>
  // }

  // const statusBodyTemplate7 = (rowData) => {
  //     return <Link to={`/update-user/${rowData.User_ID}`} className={`btn btn-primary text-primary font-bold cursor-pointer`}  >
  // update user
  //     </Link>
  // }
  // const statusBodyTemplate8 = (rowData) => {
  //     return <Link to={`/single-dcr?lga=${rowData.LGA_ID}&id=${rowData.User_ID}`} className={`btn btn-primary text-primary font-bold cursor-pointer`}  >
  // view
  //     </Link>
  // }
  // const statusBodyTemplate9 = (rowData) => {
  //     return <p  className={`btn btn-primary text-primary font-bold cursor-pointer`} onClick={()=>handleUnlink(rowData.User_ID)}  >
  //   unlink
  //     </p>
  //   }
  const handleUnlink = async (id) => {
    // console.log(id)
    try {
      const statz = await authFetch.get(`/users/unlink-device/${id}`);
      console.log(statz.data);
      if (statz.data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message}`,
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${statz.data.message}.`,
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
    }
  };
  const clearFilter1 = () => {
    setLga((prev) => {
      return [];
    });
    setUserState("");
    //    setStates((prev)=>{
    //     return []
    //    })
    setSearch((prev) => {
      return "";
    });
    setPage(1);
    //    window.location.reload()
  };
  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        />

        <Dropdown
          value={userState}
          options={state}
          onChange={changeState}
          placeholder="Select State"
          optionLabel="State_Name"
          optionValue="State_ID"
        />

        <Dropdown
          value={userLga}
          options={lga}
          onChange={(e) => setUserLga(e.value)}
          placeholder="Select lga"
          optionLabel="LGA_Name"
          optionValue="LGA_ID"
        />

        {/* <Dropdown value={Sex} options={sexOptions} onChange={(e) => dispatch(handleChange({ name:'Sex', value:e.value }))} placeholder="Select lga"/> */}

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      <div className="grid mt-2">
        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <Button
                label="Go back"
                icon="pi pi-arrow-left"
                className="p-button-sm my-3"
                onClick={goBack}
              />
              <span className="text-xl font-medium text-900">DCR List</span>
              <div className="flex align-items-center export-buttons">
                {/* <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" data-pr-tooltip="CSV" /> */}
                {/* remove */}

                <Link to={`/dcr-new`}>
                  <Button
                    type="button"
                    icon="pi pi-user"
                    label="Create new"
                    className="p-button-success mr-2"
                  />
                </Link>
                {/* <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" /> */}
                {/* <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" /> */}
              </div>
            </div>
            <DataTable
              value={products}
              ref={dt}
              filters={filters1}
              loading={loading1}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              //  paginator
              //  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              //  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5,10,50]}
            >
              {/* <Column field="id" header="Device Id"></Column> */}
              {/* <Column field="name" header="Certificate Number"></Column> */}
              <Column field="UserName" header="Name"></Column>
              <Column field="Email" header="Email"></Column>
              <Column field="Phone_No" header="Phone number"></Column>

              <Column field="User_ID" header="Id"></Column>
              <Column field="" header="State" body={statusBodyTemplate4} />
              {/* <Column field="" header="Actions" body={statusBodyTemplate5} />
                        <Column field="" header="" body={statusBodyTemplate6} />
                        <Column field="" header="" body={statusBodyTemplate7} /> */}
              <Column field="" header="" body={statusBodyTemplate8} />
              {/* <Column field="" header="" body={statusBodyTemplate9} /> */}
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={basicRows}
              totalRecords={tot}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>

        <Toast ref={toast} />
      </div>
    </>
  );
};

export default DcrList;
