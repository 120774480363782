import { useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown"; // Ensure you have the correct import for Dropdown
import { InputText } from "primereact/inputtext";

const FilterHeader = ({ filters, onFilterChange }) => {
  const [selectedValues, setSelectedValues] = useState(
    filters.reduce((acc, filter) => {
      acc[filter.name] = filter.defaultValue;
      return acc;
    }, {})
  );

  const [, setDebouncedSearchValues] =
    useState(selectedValues);

  const debounceTimeoutRef = useRef(null);

  const handleFilterChange = (filterName, value) => {
    const updatedValues = { ...selectedValues, [filterName]: value };
    setSelectedValues(updatedValues);
    if (filterName.includes("search")) {
      clearTimeout(debounceTimeoutRef.current);
      debounceTimeoutRef.current = setTimeout(() => {
        setDebouncedSearchValues(updatedValues);
        onFilterChange(updatedValues);
      }, 1000);
    } else {
      onFilterChange(updatedValues);
    }
  };

  return (
    <div className="md:flex gap-4 justify-content-between align-items-center">
      <div className="flex flex-wrap w-full justify-content-end ">
        {filters.map((filter, filterIndex) => (
          <div
            key={filterIndex}
            className="col-12 md:col-3 flex flex-column gap-1"
          >
            <label className="text-sm">
              {filter.type === "dropdown"
                ? `Filter by ${filter.label}`
                : `${filter.label}`}
            </label>
            {filter.type === "dropdown" ? (
              <Dropdown
                value={selectedValues[filter.name]}
                options={filter.options}
                className="w-full border-1 border-green-700 "
                onChange={(e) => handleFilterChange(filter.name, e.value)}
                placeholder={`Select ${filter.label}`}
                optionLabel={filter.optionLabel}
                optionValue={filter.optionValue}
                // showClear={selectedValues[filter.name]}
              />
            ) : filter.type === "search" ? (
              <InputText
                value={selectedValues[filter.name]}
                onChange={(e) =>
                  handleFilterChange(filter.name, e.target.value)
                }
                placeholder={`${filter.label}`}
                className="w-full border-1 border-green-700"
                type="search"
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterHeader;
