import { useEffect, useState, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import naija from "../assets/images/ci.svg";
import { Tooltip } from "primereact/tooltip";
import authFetch from "../axios";
import { Dropdown } from "primereact/dropdown";
import { SerialNumberTemplate } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "../helpers/hooks/useDebounce";

const PendingHospital = () => {
  const [loading1, setLoading1] = useState(false);
  const [result_per_page, set_result_per_page] = useState(100);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(20);
  const [filters1, setFilters1] = useState(null);
  const [products, setProducts] = useState([]);
  const [stats, setStats] = useState([]);
  const [tot, setTot] = useState(null);
  const [search, setSearch] = useState("");
  const [statuss, setStatus] = useState("pending");
  const [page, setPage] = useState(1);
  const { value: debouncedValue } = useDebounce(search, 1000);
  useEffect(() => {
    const getDatz = async () => {
      setLoading1(true);
      const statz = await authFetch.get(`/hospital`, {
        params: {
          search: debouncedValue,
          status: statuss,
          result_per_page,
          page,
        },
      });
      console.log(statz.data.result);
      setProducts(statz.data.result);
      setTot(statz.data.pagination.total);
      setLoading1(false);
    };
    getDatz();
  }, [debouncedValue, page, statuss]);

  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setPage(event.page + 1);
  };

  useEffect(() => {
    const getStatz = async () => {
      const statz = await authFetch.get(`/hospital/stats/count-by-status
`);
      console.log(statz.data);
      setStats(statz.data);
    };
    getStatz();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [debouncedValue, statuss]);
  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-hospital/${rowData?.id}`}
      >
        VIEW
      </Link>
    );
  };

  const statusBodyTemplate3 = (rowData) => {
    return (
      <p className={`text-primary font-bold`}>{rowData?.cac_number ?? ""}</p>
    );
  };

  const statusBodyTemplate4 = (rowData) => {
    return (
      <p className={` text-primary font-bold`}>
        {rowData?.contact_email ?? ""}
      </p>
    );
  };

  const statusBodyTemplate5 = (rowData) => {
    return (
      <p className={` text-primary font-bold`}>
        {rowData?.contact_phone ?? ""}
      </p>
    );
  };
  const clearFilter1 = () => {
    setSearch((prev) => {
      return "";
    });
    setStatus((prev) => {
      return "pending";
    });
    setPage(1);
    //    window.location.reload()
  };

  const statusOption = [
    {
      Status_Name: "Pending",
      Status_ID: "pending",
    },
    {
      Status_Name: "Approved",
      Status_ID: "approved",
    },
    {
      Status_Name: "Rejected",
      Status_ID: "rejected",
    },
  ];
  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        />

        <Dropdown
          value={statuss}
          options={statusOption}
          onChange={(e) => setStatus(e.value)}
          placeholder="Select Status"
          optionLabel="Status_Name"
          optionValue="Status_ID"
        />

        {/* <Dropdown value={Sex} options={sexOptions} onChange={(e) => dispatch(handleChange({ name:'Sex', value:e.value }))} placeholder="Select lga"/> */}

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="hospital name"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);
  const { serials } = useTableSerial({
    data: products,
    currentPage: page,
    pageSize: result_per_page,
  });

  return (
    <>
      <div className="grid my-3">
        <div className="col-12 md:col-6 lg:col-4">
          <div
            className=" shadow-2 p-3 border-round text-white"
            style={{ background: "#0E7706" }}
          >
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Pending Hospitals
                </span>
                <div className=" font-bold text-md">
                  {stats[2]?.count ?? "0"}
                </div>
                <div className="flex mt-2">
                  <i className="pi pi-arrow-up  text-xs font-bold"></i>
                  <span className="text-xs  font-bold">4.6%</span>
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-4">
          <div className="bg-white shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Approved Hospitals
                </span>
                <div className=" font-bold text-md">
                  {stats[0]?.count ?? "0"}
                </div>
                <div className="flex mt-2">
                  <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                  <span className="text-xs text-green-500 font-bold">4.6%</span>
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 md:col-6 lg:col-4">
          <div className="bg-white shadow-2 p-3 border-round">
            <div className="flex justify-content-between mb-3">
              <div>
                <span className="block  text-sm font-medium  mb-3">
                  Rejected Hospitals
                </span>
                <div className=" font-bold text-md">
                  {stats[1]?.count ?? "0"}
                </div>
                <div className="flex mt-2">
                  <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                  <span className="text-xs text-green-500 font-bold">4.6%</span>
                </div>
              </div>
              <div
                className="flex align-items-center justify-content-center "
                style={{ width: "2.8rem", height: "2.8rem" }}
              >
                <img src={naija} className="w-full" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid mt-2">
        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900">
                Hospital list
              </span>
              <div className="flex align-items-center export-buttons"></div>
            </div>
            <DataTable
              value={products}
              ref={dt}
              filters={filters1}
              loading={loading1}
              stripedRows
              responsiveLayout="stack"
              header={header1}
            >
              <Column
                field="id"
                header="S/N"
                body={(cell) => SerialNumberTemplate(cell, serials, products)}
              ></Column>
              <Column field="name" header="Name"></Column>
              <Column field="type" header="Type"></Column>
              <Column field="category" header="category"></Column>
              <Column field="" header="CAC" body={statusBodyTemplate3} />
              <Column
                field=""
                header="Contact email"
                body={statusBodyTemplate4}
              />
              <Column
                field=""
                header="Contact phone"
                body={statusBodyTemplate5}
              />
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={basicRows}
              totalRecords={tot}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingHospital;
