import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import { Dropdown } from 'primereact/dropdown';
import authFetch from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
// import { MultiSelect } from 'primereact/multiselect';
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
const SingleApproved = () => {
  const { id } = useParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  // const cities = [
  //     { name: 'Birth Registrations', code: 'NY' },
  //     { name: 'Death Registrations', code: 'RM' },
  //     { name: 'Birth Registrations & Death Registrations', code: 'LDN' },

  // ];sz
  const [loading, setLoading] = useState(true);
  // const guy=[1,2]
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [appr, setApproveLoading] = useState(false);
  // eslint-disable-next-line
  const [selectedCity1, setSelectedCity1] = useState(null);
  const [products, setProducts] = useState({});
  // eslint-disable-next-line
  const [permissions, setPermissions] = useState([
    {
      name: "level1",
      value: "level1",
    },
    {
      name: "level2",
      value: "level2",
    },
    {
      name: "level3",
      value: "level3",
    },
    {
      name: "level4",
      value: "level4",
    },
    {
      name: "level5",
      value: "level5",
    },
  ]);

  const [selectedPerm, setSelectedPerm] = useState(null);
  //   const[city,setCity] = useState();
  // eslint-disable-next-line
  const onCityChange = (e) => {
    setSelectedCity1(e.value);
  };
  const dialogFuncMap = {
    displayBasic2: setDisplayBasic2,
  };

  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const reject = async () => {
    const data = {
      id: Number(id),
      status: "rejected",
      verification_level: "level1",
    };

    try {
      const statz = await authFetch.patch(
        `/verification/user/change-status`,
        data
      );

      if (statz.data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message}`,
        });

        // setPwd('');
        // navigate('/')
        window.location.reload();
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
      console.log(error);
    }
  };

  const approve = async () => {
    setApproveLoading(true);

    const data = {
      id: Number(id),
      status: "approved",
      verification_level: selectedPerm,
    };

    try {
      const statz = await authFetch.patch(
        `/verification/user/change-status`,
        data
      );

      if (statz.data.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message}`,
        });
        setApproveLoading(false);
        // setPwd('');
        // navigate('/')
        window.location.reload();
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
      console.log(error);
      setApproveLoading(false);
    }

    onHide("displayBasic2");
  };

  const renderFooter = (name) => {
    return (
      <div className="flex justify-content-between">
        <Button
          label="Close"
          icon="pi pi-times"
          onClick={() => onHide(name)}
          className="p-button-text"
        />
        <Button
          label="Approve"
          loading={appr}
          icon="pi pi-check"
          onClick={() => approve()}
          className="p-button-success"
        />
      </div>
    );
  };

  useEffect(() => {
    const getDatz = async () => {
      const statz1 = await authFetch.get(`/verification/user/single/${id}`);
      // console.log(statz1)
      setProducts(statz1.data.data);
      setLoading(false);
    };
    getDatz();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round my-2">
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm w-2"
          onClick={goBack}
        />
        <div className="flex align-content-center justify-content-between">
          <div className="text-900 font-bold ">
            {products?.user?.organization_name ?? ""}
          </div>
          <div className="flex align-content-center  md:flex-wrap py-2 gap-4 justify-content-end	 ">
            {/* <Button label="View license" severity="secondary" text onClick={()=>setlicVisible(true)} />
<Button label="View permit" severity="success" text onClick={()=>setVisible(true)} /> */}
          </div>
        </div>

        <div className="flex flex-column md:flex-row md:flex-wrap pt-2 border-top-1 border-bottom-1 border-green-300">
          {/* <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Particulars of child</div> */}
          <div className="col-12 md:col-3 p-3">
            <div className="text-500 font-medium mb-2">identity</div>
            <div className="text-900">{products?.user?.identity ?? ""}</div>
          </div>

          <div className="col-12 md:col-3 p-3">
            <div className="text-500 font-medium mb-2">Organization Type</div>
            <div className="text-900">
              {products?.user?.organization_type ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-3 p-3">
            <div className="text-500 font-medium mb-2"> organization name</div>
            <div className="text-900">
              {products?.user?.organization_name ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-3 p-3">
            <div className="text-500 font-medium mb-2">status</div>
            <div className="text-900">{products?.user?.status ?? ""}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-3 p-3">
            <div className="text-500 font-medium mb-2"> organization email</div>
            <div className="text-900">
              {products?.user?.organization_email ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
        </div>

        {/* <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Particulars of informant</div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
            
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
          
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
            
        </div>
      
       
       
    </div> */}
        <div className="flex gap-3 flex-column md:flex-row">
          {products?.user?.status !== "approved" && (
            <Button
              label="Approve"
              className="p-button-success my-2"
              onClick={() => onClick("displayBasic2")}
            />
          )}
          {products?.user?.status === "approved" && (
            <Button
              label="Decline"
              className="p-button-warning my-2"
              onClick={() => reject()}
            />
          )}
        </div>
      </div>
      <Dialog
        header="Assign Level"
        visible={displayBasic2}
        style={{ width: "30vw", color: "#565656" }}
        breakpoints={{ "960px": "95vw" }}
        footer={renderFooter("displayBasic2")}
        onHide={() => onHide("displayBasic2")}
      >
        <div className="flex">
          <div className="w-full px-4">
            {/* <MultiSelect
         maxSelectedLabels={10}
        value={selectedPerm}
        // value={val&& val}
        onChange={(e) => setSelectedPerm(e.value)} options={permissions} 
 placeholder="Select user permissions"  className="w-full" /> */}
            <Dropdown
              value={selectedPerm}
              options={permissions}
              onChange={(e) => setSelectedPerm(e.value)}
              className="w-full"
              optionLabel="name"
              placeholder="Select Level"
            />
          </div>
          {/* <div className='w-2'>
                   <div className="flex relative align-items-center justify-content-center mx-1 py-3 min-h-full">
            <div className="border-left-1 border-300 top-0 left-50 absolute h-full"></div>
            <div className="py-2 z-1 surface-0">
                <span className="text-900 font-medium">OR</span>
            </div>
                    </div>
                   </div> */}

          {/* <div className='w-5'>
                    <div className="field mb-4 col-12 md:col-6">
                    <div className="text-sm text-900 mb-2">Employee Tax ID</div>
            <label htmlFor="company_name" className="text-sm text-900 ">Input employee Tax Id to continue</label>
            <InputText id="company_name" type="text" className='' placeholder='Input employee Tax Id' />
        </div>
        <Button label="Continue" className="w-8 mb-7 md:mb-0"  />
    </div> */}
        </div>
      </Dialog>

      <Toast ref={toast} position="top-center" />
    </div>
  );
};

export default SingleApproved;
