import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useRef, useState } from "react";
import ErrorMessageExtractor from "../../helpers/utils/errorHandler";
import { Toast } from "primereact/toast";
import { RegTypeOptions } from "../../helpers/constants";

const ApprovalActions = ({
  id,
  records,
  setApproveSuccess,
  approveSuccess,
  approveApi,
  queryApi,
  ApprovalStatus,
  approvalStatusType,
}) => {
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [queryVisible, setQueryVisible] = useState(false);
  const [isQueryReasons, setIsQueryReasons] = useState(false);
  const [queryReasons, setQueryReasons] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleApproveRecord = async () => {
    setSubmitLoading(true);
    try {
      const response = await approveApi(id);
      setSubmitLoading(false);
      if (response.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${response.message ?? "Registration approved"}`,
        });
        setVisible(false);
        setTimeout(() => {
          setApproveSuccess(!approveSuccess);
        }, 1500);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${response.message ?? "Registration not approved"}`,
        });
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      const message = ErrorMessageExtractor.errorParser(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${message ?? "Record Not Approved"}`,
      });
      setVisible(false);
    }
  };
  const handleQueryRecord = async () => {
    setSubmitLoading(true);
    try {
      const response = await queryApi({
        id: Number(id),
        reason: queryReasons,
      });
      setSubmitLoading(false);
      if (response.success === true) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${response.message ?? "Registration approved"}`,
        });
        setQueryVisible(false);
        setTimeout(() => {
          setApproveSuccess(!approveSuccess);
        }, 1500);
      } else {
        setSubmitLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${response.message ?? "Registration not approved"}`,
        });
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      const message = ErrorMessageExtractor.errorParser(error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${message ?? "Record Not Queried"}`,
      });
    }
  };
  return (
    <>
      <div className="flex gap-3">
        {[approvalStatusType.PENDING, approvalStatusType.QUERIED].includes(
          ApprovalStatus
        ) && (
          <Button
            label="Approve"
            className="p-button-success my-2"
            onClick={() => {
              setVisible(true);
            }}
          />
        )}
        {+records?.self !== RegTypeOptions.SelfService && (
          <>
            {ApprovalStatus !== approvalStatusType.QUERIED && (
              <div className="flex gap-3">
                <Button
                  label="Query"
                  className="p-button-warning my-2"
                  onClick={() => {
                    setQueryVisible(true);
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>

      <Dialog
        header="NOTE"
        visible={visible}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
      >
        <p>Are you sure you want to approve this registration?</p>
        <div className="w-full flex gap-3 justify-content-between">
          <Button
            label="YES"
            className="w-full p-button-success my-2"
            loading={submitLoading}
            onClick={handleApproveRecord}
          />
          <Button
            label="NO"
            className=" w-full bg-transparent text-primary my-2"
            onClick={() => setVisible(false)}
          />
        </div>
      </Dialog>
      <Dialog
        header="NOTE"
        visible={queryVisible}
        style={{ width: "30vw" }}
        onHide={() => setQueryVisible(false)}
      >
        {isQueryReasons ? (
          <>
            <InputTextarea
              value={queryReasons}
              placeholder="Enter Reason for Querying this record"
              onChange={(e) => setQueryReasons(e.target.value)}
              rows={5}
              cols={40}
            />{" "}
            <div className="w-full flex gap-3 justify-content-between">
              <Button
                label="SUBMIT"
                onClick={handleQueryRecord}
                loading={submitLoading}
                className="w-full p-button-warning my-2"
              />
              <Button
                label="CANCEL"
                onClick={() => {
                  setQueryVisible(false);
                }}
                className=" w-full bg-transparent text-primary my-2"
              />
            </div>
          </>
        ) : (
          <>
            <p>Are you sure you want to query this registration?</p>
            <div className="w-full flex gap-3 justify-content-between">
              <Button
                label="YES"
                className="w-full p-button-warning my-2"
                onClick={() => {
                  setIsQueryReasons(true);
                }}
              />
              <Button
                label="NO"
                onClick={() => {
                  setQueryVisible(false);
                }}
                className=" w-full bg-transparent text-primary my-2"
              />
            </div>
          </>
        )}
      </Dialog>
      <Toast ref={toast} position="top-center" />
    </>
  );
};

export default ApprovalActions;
