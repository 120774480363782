import { useEffect, useState, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import authFetch from "../axios";
import { Dropdown } from "primereact/dropdown";
import { SerialNumberTemplate } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "../helpers/hooks/useDebounce";

const Verification = () => {
  const [loading1, setLoading1] = useState(false);
  const [result_per_page, set_result_per_page] = useState(100);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(20);
  const [filters1, setFilters1] = useState(null);
  const [products, setProducts] = useState([]);
  const [tot, setTot] = useState(null);
  const [search, setSearch] = useState("");
  const [statuss, setStatus] = useState("approved");
  const [page, setPage] = useState(1);
  const onBasicPageChange = (event) => {
    setBasicFirst(event.first);
    setPage(event.page + 1);
  };
  const { value: debouncedValue } = useDebounce(search, 1000);
  useEffect(() => {
    const getDatz = async () => {
      setLoading1(true);
      const params = {
        search: debouncedValue,
        result_per_page,
        page,
        status: statuss,
      };
      const statz = await authFetch.get(`/verification/user`, { params });
      console.log(statz.data.result);
      setProducts(statz.data.result);
      setTot(statz.data.pagination.total);
      setLoading1(false);
    };
    getDatz();
  }, [page, statuss, debouncedValue]);

  useEffect(() => {
    setPage(1);
  }, [debouncedValue, statuss]);
  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-approved/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };
  const clearFilter1 = () => {
    setSearch((prev) => {
      return "";
    });
    setPage(1);
    setStatus("approved");
  };

  const statusOption = [
    {
      Status_Name: "Approved",
      Status_ID: "approved",
    },
    {
      Status_Name: "Pending",
      Status_ID: "pending",
    },
    {
      Status_Name: "Rejected",
      Status_ID: "rejected",
    },
  ];
  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={clearFilter1}
        />

        <Dropdown
          value={statuss}
          options={statusOption}
          onChange={(e) => setStatus(e.value)}
          placeholder="Select Status"
          optionLabel="Status_Name"
          optionValue="Status_ID"
        />

        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="organization name"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  
  const { serials } = useTableSerial({
    data: products,
    currentPage: page,
    pageSize: result_per_page,
  });

  return (
    <>
      <div className="grid mt-2">
        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900">
                Pending Applications
              </span>
              <div className="flex align-items-center export-buttons">
              </div>
            </div>
            <DataTable
              value={products}
              ref={dt}
              filters={filters1}
              loading={loading1}
              stripedRows
              responsiveLayout="stack"
              header={header1}
            >
              <Column
                field="id"
                header="S/N"
                body={(cell) => SerialNumberTemplate(cell, serials, products)}
              ></Column>
              <Column field="organization_name" header="Name"></Column>
              <Column field="organization_type" header="Organization"></Column>
              <Column field="identity" header="identity"></Column>
              <Column field="status" header="Status" />
              <Column field="contact_person_name" header="Contact Person" />

              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={basicRows}
              totalRecords={tot}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification;
