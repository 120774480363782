import { useRef } from "react";
import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";
import { Outlet, Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/72.png";
import Avatar from "../assets/images/avatar.png";
import "./home.css";
import { logout } from "../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { Permissions, Roles } from "../helpers/constants";
import useModuleAccess from "../helpers/hooks/useModuleAccess";
import Swal from "sweetalert2";
const Homez = () => {
  const { userInfo: user } = useSelector((state) => state.user);
  const hasAccess = useModuleAccess;
  // useEffect(() => {
  //   if (user.Role_ID === Roles.TIU.toString()) {
  //     navigate("/birth/all-registrations");
  //   } else {
  //     navigate("/dashboard");
  //   }
  // }, []);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const btnRef4 = useRef(null);
  const btnRef5 = useRef(null);
  const btnRef7 = useRef(null);
  const btnRef8 = useRef(null);
  const btnRef9 = useRef(null);
  const btnRef99 = useRef(null);
  const btnRef10 = useRef(null);
  const btnRef12 = useRef(null);
  const btnRef14 = useRef(null);
  const notificationButtonRef = useRef(null);

  const canSeeBirth = useModuleAccess(Permissions.canSeeBirth);
  const canSeeBirthApproval = useModuleAccess(Permissions.canSeeBirthApproval);
  const canSeeDeath = useModuleAccess(Permissions.canSeeDeath);
  const canSeeDeathApproval = useModuleAccess(Permissions.canSeeDeathApproval);
  const canSeeAttestation = useModuleAccess(Permissions.canSeeAattestation);
  const canSeeAttestationApproval = useModuleAccess(
    Permissions.canSeeAttestationApproval
  );
  const canSeeModification = useModuleAccess(Permissions.canSeeModification);
  const canSeeBirthModificationApproval = useModuleAccess(
    Permissions.canSeeBirthModificationApproval
  );
  const canSeeDeathModificationApproval = useModuleAccess(
    Permissions.canSeeDeathModificationApproval
  );
  const canSeeAttestationModificationApproval = useModuleAccess(
    Permissions.canSeeAttestationModificationApproval
  );

  const handleLogout = () => {
    localStorage.removeItem("userInfo-admin-v2");
    localStorage.removeItem("userInfo-admin-token-v2");
    dispatch(logout());
    nav("/unauth");
  };

  const confirmLogout = async () => {
    const result = await Swal.fire({
      title: "Confirm",
      text: `Are you sure you want to logout?`,
      showCancelButton: true,
      confirmButtonColor: "#0b6916",
      confirmButtonText: "Sign Out",
    });
    if (result.value) {
      handleLogout();
    }
  };

  return (
    <div className="min-h-screen max-w-full flex relative lg:static surface-ground">
      <div
        id="app-sidebar-1"
        className="surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:sticky left-0 top-0 z-2 select-none"
        // style={{ maxWidth: "20%" }}
      >
        <div
          className="flex flex-column h-full"
          style={{ backgroundColor: "white" }}
        >
          <div
            className="flex align-items-center px-5 py-3 flex-shrink-0"
            style={{ backgroundColor: "white" }}
          >
            <Link
              to="/"
              className="flex flex-column align-items-center cursor-pointer gap-2"
            >
              <img src={Logo} alt="hyper-300" height="45" />
              <center className="text-sm uppercase text-green-500 font-bold">
                VITAL REG Management System
              </center>
            </Link>
          </div>
          <div
            className="hidden lg:flex align-items-center px-5 flex-shrink-0 border-bottom-1 border-top-1 border-200 "
            style={{ height: "65px", backgroundColor: "white" }}
          >
            <div className="flex align-items-center cursor-pointer gap-4">
              <img
                src={Avatar}
                alt="hyper-300"
                height="45"
                style={{
                  borderRadius: "50%",
                }}
              />
              <div className="flex flex-column ">
                <span className="text-sm text-green-500 uppercase font-bold">
                  {user?.UserName}
                </span>
                <span className="text-sm font-medium">{user?.roles?.name}</span>
              </div>
            </div>
          </div>
          <div className="overflow-y-auto scrollable-container text-sm mt-3">
            <ul className="list-none p-3 m-0 ">
              {![Roles.TIU].includes(+user?.Role_ID) && (
                <li className="border-bottom-1 border-200">
                  <Link
                    to="/dashboard"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150  w-full text-dark"
                  >
                    <i className="pi pi-home mr-2 text-green-500 font-bold"></i>
                    <span className="font-medium text-dark font-medium">
                      Dashboard
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}

              {(canSeeBirth || canSeeBirthApproval) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef8}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef8}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-user mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">
                        Birth registrations
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    {canSeeBirth && (
                      <>
                        <li>
                          <Link
                            to="/birth"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Statistics
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              +user.Role_ID === Roles.SubAdmin && user.State_ID
                                ? `/birth/lga/${user.State_ID}`
                                : "/birth/registrations"
                            }
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}

                    {canSeeBirthApproval && (
                      <>
                        <li>
                          <Link
                            to="/birth/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              All Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}

              {(canSeeDeath || canSeeDeathApproval) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef7}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef7}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                      transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-file-excel mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">
                        Death registrations
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    {canSeeDeath && (
                      <>
                        <li>
                          <Link
                            to="/death"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Statistics
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              +user.Role_ID === Roles.SubAdmin && user.State_ID
                                ? `/death/lga/${user.State_ID}`
                                : "/death/registrations"
                            }
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}

                    {canSeeDeathApproval && (
                      <>
                        <li>
                          <Link
                            to="/death/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              All Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}

              {useModuleAccess(Permissions.canSeeStillbirth) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef14}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef14}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">Still Birth</span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li>
                      <Link
                        to="/still"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark">
                          Statistics
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={
                          +user.Role_ID === Roles.SubAdmin && user.State_ID
                            ? `/still/lga/${user.State_ID}`
                            : "/still/registrations"
                        }
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark">
                          Registrations
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {(canSeeAttestation || canSeeAttestationApproval) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef9}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef9}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">
                        Birth Attestations
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    {canSeeAttestation && (
                      <>
                        <li>
                          <Link
                            to="/attestation"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Statistics
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={
                              +user.Role_ID === Roles.SubAdmin && user.State_ID
                                ? `/attestation/lga/${user.State_ID}`
                                : "/attestation/registrations"
                            }
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}

                    {canSeeAttestationApproval && (
                      <>
                        <li>
                          <Link
                            to="/attestation/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              All Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}

              {(canSeeModification ||
                canSeeAttestationModificationApproval ||
                canSeeBirthModificationApproval ||
                canSeeDeathModificationApproval) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef99}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef99}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-user-edit mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">
                        Modifications
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>

                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    {canSeeModification && (
                      <>
                        {" "}
                        <li>
                          <Link
                            to="/modify/birth/registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Birth registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/modify/death/registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Death Registrations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/modify/attest/registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Birth attestations
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                    {canSeeBirthModificationApproval && (
                      <>
                        <li>
                          <Link
                            to="birth-modifications/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              All Birth modifications
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                    {canSeeDeathModificationApproval && (
                      <>
                        <li>
                          <Link
                            to="death-modifications/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              All Death modifications
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                    {canSeeAttestationModificationApproval && (
                      <>
                        <li>
                          <Link
                            to="attestation-modifications/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-book mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              All Attestation modifications
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
              {hasAccess([
                Permissions.canSeeBirthNotification,
                Permissions.canSeeAttestationWithNoNIN,
              ]) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={notificationButtonRef}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={notificationButtonRef}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-bell mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">
                        Notifications
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>

                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    {hasAccess(Permissions.canSeeBirthNotification) && (
                      <>
                        <li>
                          <Link
                            to="birth-notifications/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Birth Notifications
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                    {hasAccess(Permissions.canSeeAttestationWithNoNIN) && (
                      <>
                        <li>
                          <Link
                            to="attestation-without-nin/all-registrations"
                            className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                          >
                            <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                            <span className="font-medium text-dark">
                              Attestation without NIN
                            </span>
                            <Ripple />
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}

              {useModuleAccess(Permissions.canSeeVerification) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef10}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef10}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-check mr-2 text-green-500 font-bold"></i>
                      <span className="text-dark font-medium">
                        Verification
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li>
                      <Link
                        to="/all-organizations"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark">
                          Organizations
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                    <li>
                      <Link
                        // to="/pending-verification"
                        className="p-ripple flex align-items-center cursor-pointer p-3 text-gray-400 border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark">
                          Verifications{" "}
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {useModuleAccess(Permissions.canSeeHospital) && (
                <li className="border-bottom-1 border-200">
                  <StyleClass
                    nodeRef={btnRef12}
                    selector="@next"
                    enterClassName="hidden"
                    enterActiveClassName="slidedown"
                    leaveToClassName="hidden"
                    leaveActiveClassName="slideup"
                  >
                    {/* eslint-disable-next-line */}
                    <a
                      ref={btnRef12}
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-plus mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark font-medium">
                        Hospitals
                      </span>
                      <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                      <Ripple />
                    </a>
                  </StyleClass>
                  <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                    <li>
                      <Link
                        to="/hospital/public"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark">
                          Statistics
                        </span>
                        <Ripple />
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/hospital/pending"
                        className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                      >
                        <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                        <span className="font-medium text-dark">
                          Hospital list
                        </span>
                        <Ripple />
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {/* {useModuleAccess(Permissions.canManageUser) && ( */}
              {/* <li className="border-bottom-1 border-200">
                <StyleClass
                  nodeRef={btnRef13}
                  selector="@next"
                  enterClassName="hidden"
                  enterActiveClassName="slidedown"
                  leaveToClassName="hidden"
                  leaveActiveClassName="slideup"
                >
=                  <a
                    ref={btnRef13}
                    className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                transition-duration-150 transition-colors w-full"
                  >
                    <i className="pi pi-users mr-2 text-green-500 font-bold"></i>
                    <span className="font-medium text-dark font-medium">
                      User access
                    </span>
                    <i className="pi pi-chevron-right ml-auto mr-1 text-green-500"></i>
                    <Ripple />
                  </a>
                </StyleClass>
                <ul className="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400 transition-ease-in-out">
                  <li>
                    <Link
                      to="/admins"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">Admins</span>
                      <Ripple />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/registrars-list"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">Registrars</span>
                      <Ripple />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/dcr-list"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">DCR</span>
                      <Ripple />
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/directors"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">
                        State Directors
                      </span>
                      <Ripple />
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/adhoc-list"
                      className="p-ripple flex align-items-center cursor-pointer p-3  border-round 
                    transition-duration-150 transition-colors w-full"
                    >
                      <i className="pi pi-chart-line mr-2 text-green-500 font-bold"></i>
                      <span className="font-medium text-dark">Adhoc</span>
                      <Ripple />
                    </Link>
                  </li>
                </ul>
              </li> */}
              {/* )} */}
              {useModuleAccess(Permissions.canSeeTransactionReport) && (
                <li className="border-bottom-1 border-200">
                  <Link
                    to="/transaction-report"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150  w-full text-dark"
                  >
                    {/* <a className="p-ripple flex align-items-center cursor-pointer p-3  
                transition-duration-150 transition-colors w-full "> */}
                    <i className="pi pi-file mr-2 text-green-500 font-bold"></i>
                    <span className="font-medium text-dark font-medium">
                      Transaction report
                    </span>
                    <Ripple />
                    {/* </a> */}
                  </Link>
                </li>
              )}

              {useModuleAccess(Permissions.canManageUser) && (
                <li className="border-bottom-1 border-200">
                  <Link
                    to="/user-access"
                    className="flex align-items-center cursor-pointer p-3 transition-duration-150  w-full text-dark"
                  >
                    <i className="pi pi-home mr-2 text-green-500 font-bold"></i>
                    <span className="font-medium text-dark font-medium">
                      User Access
                    </span>
                    <Ripple />
                  </Link>
                </li>
              )}

              <li onClick={confirmLogout}>
                {/* eslint-disable-next-line */}
                <a
                  className="p-ripple flex align-items-center cursor-pointer p-3 border-round 
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-sign-out mr-2 text-green-500"></i>
                  {/* <span className="font-medium">Sign Out</span> */}
                  <span className="font-medium text-dark font-medium">
                    Sign Out
                  </span>
                  <Ripple />
                </a>
              </li>
            </ul>
          </div>

          {/* <div className="mt-auto mx-3">
            <ul className="list-none p-2 m-0 hidden">
              <li onClick={handleLogout}>
                <a
                  className="p-ripple flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-bluegray-100 hover:text-bluegray-50
            transition-duration-150 transition-colors w-full"
                >
                  <i className="pi pi-sign-out mr-2 text-green-500"></i>
                  <span className="font-medium text-green-500">Sign Out</span>
                  <Ripple />
                </a>
              </li>
            </ul>
            <StyleClass
              nodeRef={btnRef3}
              selector="@prev"
              enterClassName="hidden"
              enterActiveClassName="fadein"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeout"
            >
              <a
                ref={btnRef3}
                className="p-ripple my-3 px-3 py-2 flex align-items-center hover:bg-bluegray-900 border-round cursor-pointer text-bluegray-100 hover:text-bluegray-50
        transition-duration-150 transition-colors w-full"
              >
                <span className="font-medium uppercase text-green-500">
                  {user?.UserName}
                </span>

                <i className="pi pi-chevron-up ml-auto text-green-500"></i>
                <Ripple />
              </a>
            </StyleClass>
          </div> */}
        </div>
      </div>
      <div className="min-h-screen overflow-y-auto flex flex-column relative flex-auto">
        <div
          className="flex justify-content-between align-items-center px-5  shadow-2 relative lg:sticky top-0 left-0 border-bottom-1 surface-border z-1"
          style={{ height: "63px", backgroundColor: "#0E7706" }}
        >
          <div className="flex">
            <p className="hidden lg:block text-white font-bold ">Dashboard</p>
            <StyleClass
              nodeRef={btnRef4}
              selector="#app-sidebar-1"
              enterClassName="hidden"
              enterActiveClassName="fadeinleft"
              leaveToClassName="hidden"
              leaveActiveClassName="fadeoutleft"
              hideOnOutsideClick
            >
              {/* eslint-disable-next-line */}
              <a
                ref={btnRef4}
                className="p-ripple cursor-pointer block lg:hidden text-white mr-3"
              >
                <i className="pi pi-bars text-4xl"></i>
                <Ripple />
              </a>
            </StyleClass>
          </div>
          <StyleClass
            nodeRef={btnRef5}
            selector="@next"
            enterClassName="hidden"
            enterActiveClassName="fadein"
            leaveToClassName="hidden"
            leaveActiveClassName="fadeout"
            hideOnOutsideClick
          >
            {/* eslint-disable-next-line */}
            <a
              ref={btnRef5}
              className="p-ripple cursor-pointer block lg:hidden text-700"
            >
              <i className="pi pi-ellipsis-v text-2xl"></i>
              <Ripple />
            </a>
          </StyleClass>
        </div>
        <div className="p-5 overflow-y-auto flex flex-column flex-auto">
          <div className=" flex-auto">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homez;
