import { Image } from "primereact/image";

const SupportingDocuments = ({ SupportingDocumentArray }) => {
  const documents =
    SupportingDocumentArray?.filter((doc) => doc.file !== null) || [];

  if (documents.length === 0) {
    return <p>No supporting documents available.</p>;
  }

  const getDocumentIcon = (mimeType) => {
    switch (mimeType) {
      case "application/pdf":
        return "pi pi-file-pdf text-red-700";
      case "application/msword":
      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        return "pi pi-file-word text-blue-700";
      case "application/vnd.ms-excel":
      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        return "pi pi-file-excel text-green-700";
      default:
        return "pi pi-file";
    }
  };

  return (
    <section
      className="flex flex-wrap gap-3 w-full"
      style={{ wordBreak: "break-word" }}
    >
      {documents?.map((doc, index) => (
        <div className="flex flex-wrap gap-3 w-full md:max-w-20rem" key={index}>
          <div
            className={`card shadow-lg h-[200px] rounded-md overflow-hidden ${
              doc.mimeType === "application/pdf" ||
              doc.mimeType.startsWith("application/")
                ? "md:flex items-center px-2 justify-center"
                : ""
            }`}
          >
            {doc.mimeType.startsWith("image/") ? (
              <>
                <p className="capitalize">
                  <span className="font-bold">Document Type: </span>
                  {doc.type?.replace("-", " ")}
                </p>
                <Image
                  src={doc.file}
                  zoomSrc={doc.file}
                  alt={doc.type}
                  preview
                  width="250"
                  height="300"
                />
              </>
            ) : (
              <div className="gap-3 flex flex-column align-items-center justify-content-between block text-center">
                <p className="capitalize">
                  <span className="font-bold">Document Type: </span>
                  {doc.type?.replace("-", " ")}
                </p>
                <a
                  className="text-xs"
                  href={doc.file}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    style={{ fontSize: "5rem" }}
                    className={`${getDocumentIcon(doc.mimeType)} w-full`}
                  />
                </a>
                <div />
              </div>
            )}
          </div>
        </div>
      ))}
    </section>
  );
};

export default SupportingDocuments;
