import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";

import { useNavigate, useParams } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import authFetch from "../axios";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";

const SingleAttestMod = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const toast = useRef(null);
  //   const dispatch = useDispatch();
  const [loadingCertificate, setLoadingCertificate] = useState(false);
  const [loadingRecord, setLoadingRecord] = useState(true);
  const [products, setProducts] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);
  const [imgCert, setImgCert] = useState("");
  const viewBirth = async () => {
    // /cert/birth/${reg?.Certificate_No}
    setLoadingCertificate(true);
    try {
      const res = await authFetch.get(
        `/cert/attestation/${products?.attestation_number}`
      );
      console.log(res.data);
      setLoadingCertificate(false);
      setImgCert(res.data);
      setVisibleModal(true);
    } catch (error) {
      setLoadingCertificate(false);
      console.log(error);
      toast.current.show({
        severity: "error",
        summary: "",
        detail: `${error.response.data.message}`,
      });
    }
  };
  useEffect(() => {
    const getDatz = async () => {
      const statz1 = await authFetch.get(
        `/modification/birth-attestation/single/${id}`
      );
      console.log(statz1);
      setProducts(statz1.data.data);
      setLoadingRecord(false);
    };
    getDatz();
  }, [id]);

  if (loadingRecord) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div className="surface-card p-4 shadow-2 border-round">
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="surface-card p-4 shadow-2 border-round my-2">
        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Particulars of Applicant
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">firstname</div>
            <div className="text-900">
              {products?.personData?.firstname ?? ""}{" "}
            </div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">middlename</div>
            <div className="text-900">
              {products?.personData?.middle_name ?? "-"}{" "}
            </div>
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">surname</div>
            <div className="text-900">
              {products?.personData?.surname ?? ""}{" "}
            </div>
          </div>
        </div>

        <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className="col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold ">
            Modification Information
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Modification type</div>
            <div className="text-900">
              {products?.Modification[0]?.mod_type ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Field</div>
            <div className="text-900">
              {products?.Modification[0]?.mod_field ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>
          <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">modification reason</div>
            <div className="text-900">
              {products?.Modification[0]?.mod_reason ?? ""}
            </div>
            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          <div className="col-12 md:col-6 p-3">
            <div className="text-500 font-medium mb-2">Comment</div>
            <div className="text-900">
              {products?.Modification[0]?.comment ?? "-"}
            </div>

            {/* <div className="text-900">Elliot Alderson</div> */}
          </div>

          {/* <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">NATIONALITY</div>
            <div className="text-900">{reg?.motherData?.current_nationalityData?.Country_Name??''}</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">STATE OF ORIGIN</div>
            <div className="text-900">{reg?.motherData?.current_residence_stateData?.State_Name??''}</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LITERACY</div>
            <div className="text-900">{reg?.motherData?.current_literacy_levelData?.Literacy??''}</div>
           
        </div>

        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LEVEL OF EDUCATION</div>
            <div className="text-900">{reg?.motherData?.current_education_levelData?.Description??""}</div>
           
        </div>

        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Mother's Age at Birth</div>
            <div className="text-900">{reg?.mother_age_at_birth??""}</div>
            
        </div> */}

          {/* <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">OCCUPATION</div>
            <div className="text-900">{reg&&reg.mother?.OccupationData?}</div>
            
        </div> */}
        </div>

        <Button
          label="View Certificate"
          className="p-button-success my-2"
          onClick={viewBirth}
          loading={loadingCertificate}
        />

        <Toast ref={toast} />

        {/* <div className="card flex justify-content-center">
            <Button label="Show" icon="pi pi-external-link" onClick={() => setVisible(true)} /> */}
        <Dialog
          header="Certificate"
          visible={visibleModal}
          style={{ width: "70vw" }}
          onHide={() => setVisibleModal(false)}
        >
          <img
            src={`data:image/png;base64,${imgCert} `}
            className="w-full"
            alt=""
          />
        </Dialog>
        {/* </div> */}
      </div>
    </div>
  );
};

export default SingleAttestMod;
