import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";
// import { Link } from 'react-router-dom';
import { Button } from "primereact/button";

const UnauthorizedPage = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-content-center align-items-center min-h-screen ">
      <Card title="UNAUTHORIZED">
        <p className="m-0 font-bold">Not authorized to access this route.</p>

        <Button
          label="Go back"
          severity="danger"
          className="bg-danger my-2"
          onClick={() => {
            navigate(-1);
          }}
        />
      </Card>
    </div>
  );
};

export default UnauthorizedPage;
