import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
import authFetch from "../axios";
import { SerialNumberTemplate, dobTemplate } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { Paginator } from "primereact/paginator";
import { useDebounce } from "../helpers/hooks/useDebounce";
import {
  organizations,
  verificationStatusFilterOptions,
} from "../helpers/constants";
import { reverse_country } from "../helpers/world.helper";
import ErrorMessageExtractor from "../helpers/utils/errorHandler";
import FilterHeader from "../components/FilterHeader";
import CountUp from "react-countup";
import { BarChart3 } from "lucide-react";

const Verification2 = () => {
  const [loading1, setLoading1] = useState(false);
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [result_per_page, set_result_per_page] = useState(100);
  const [basicFirst, setBasicFirst] = useState(0);
  const [products, setProducts] = useState([]);
  const [statistics, setStatistics] = useState({});
  const [totalRecords, setTotalRecords] = useState(null);
  const [page, setPage] = useState(1);
  const onPageChange = (event) => {
    setBasicFirst(event.first);
    setPage(event.page + 1);
  };
  const [filters, setFilters] = useState({
    status: null,
    type: null,
    search: "",
  });
  const { value: debouncedValue } = useDebounce(filters.search, 1000);
  const stats = [
    {
      name: "Total Organizations",
      count: statistics?.total,
      color: "#0E7705",
    },
    {
      name: "Pending Organizations",
      count: statistics?.pending,
    },
    {
      name: "Approved Organizations",
      count: statistics?.approved,
    },
    {
      name: "Declined Organizations",
      count: statistics?.rejected,
    },
  ];
  useEffect(() => {
    const params = {
      search: debouncedValue,
      result_per_page,
      page,
      status: filters.status,
      type: filters.type,
    };

    const getAllOrganization = async () => {
      setLoading1(true);
      try {
        const { data } = await authFetch.get(`/verification/user`, { params });
        setProducts(data.result);
        setTotalRecords(data.pagination.total);
      } catch (error) {
        ErrorMessageExtractor.errorParser(error);
      } finally {
        setLoading1(false);
      }
    };
    getAllOrganization();
  }, [page, debouncedValue, filters.status, filters.type]);

  useEffect(() => {
    setPage(1);
  }, [debouncedValue, filters]);

  useEffect(() => {
    const getStatisticsCount = async () => {
      setStatisticsLoading(true);
      try {
        const { data } = await authFetch.get(
          `verification/user/verifiction-stats-count`
        );
        setStatistics(data);
      } catch (error) {
        ErrorMessageExtractor.errorParser(error);
      } finally {
        setStatisticsLoading(false);
      }
    };

    getStatisticsCount();
  }, []);

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-approved-organization/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };

  const typeOption = [
    { id: null, name: "All" },
    { id: organizations.Private, name: "Private" },
    { id: organizations.Government, name: "Government" },
    { id: organizations.NonGovernment, name: "Non Government" },
    { id: organizations.Embassy, name: "Embassy" },
    { id: organizations.NonProfit, name: "Non Profit" },
    { id: organizations.International, name: "International" },
  ];

  const filterGroups = [
    {
      name: "type",
      label: "Type",
      type: "dropdown",
      options: typeOption,
      optionLabel: "name",
      defaultValue: filters.type,
      optionValue: "id",
    },
    {
      name: "status",
      label: "Status",
      type: "dropdown",
      options: verificationStatusFilterOptions,
      defaultValue: filters.status,
      optionLabel: "name",
      optionValue: "id",
    },
    { name: "search", label: "Search", type: "search" },
  ];

  const handleFilterChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };

  const renderHeader1 = () => {
    return (
      <>
        <p className="capitalize">
          {filters?.status === "" ? "All" : filters?.status ?? "All"}{" "}
          Registrations - {totalRecords}
        </p>
        <FilterHeader
          filters={filterGroups}
          onFilterChange={handleFilterChange}
        />
      </>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  const { serials } = useTableSerial({
    data: products,
    currentPage: page,
    pageSize: result_per_page,
  });

  return (
    <>
      <div className="grid mt-2">
        <div className="mt-3  p-2 w-full m-0 grid gap-4 ">
          {stats.map((item, index) => {
            const { name, count, color } = item;
            return (
              <>
                <div
                  style={{ background: color }}
                  className="col-12 p-3 lg:col border-round-md shadow-2 "
                >
                  <div className=" flex flex-1 gap-3 w-full justify-between">
                    <div className="text-sm w-full">
                      <div
                        className={`font-medium text-xs ${
                          color ? "text-white" : ""
                        } p-0 m-0`}
                      >
                        {name}
                      </div>
                      <div className=" flex py-2 justify-content-between align-items-center">
                        {statisticsLoading ? (
                          <span className="fadein animation-duration-1000 animation-iteration-infinite text-xs">
                            ...
                          </span>
                        ) : (
                          <span
                            className={`text-xl font-bold text-wrap w-full break-all ${
                              color ? "text-white" : ""
                            } `}
                          >
                            <CountUp end={count} />
                          </span>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ background: "#0C5406" }}
                      className="flex align-items-center p-2 px-4 text-white border-round-xl "
                    >
                      <BarChart3 className="w-1.5rem h-2rem font-bold" />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <span className="text-xl font-medium text-900">
                All organizations
              </span>
              <div className="flex align-items-center export-buttons"></div>
            </div>
            <DataTable
              value={products}
              ref={dt}
              loading={loading1}
              stripedRows
              responsiveLayout="stack"
              header={header1}
            >
              <Column
                field="id"
                header="S/N"
                body={(cell) => SerialNumberTemplate(cell, serials, products)}
              ></Column>
              <Column field="organization_name" header="Name"></Column>
              <Column
                field="organization_type"
                header="Organization Type"
              ></Column>
              <Column field="organization_phone" header="Phone Number"></Column>
              <Column field="organization_email" header="Email Address" />
              <Column
                header="Country"
                body={(rowData) =>
                  dobTemplate(reverse_country(rowData?.organization_country))
                }
              />
              <Column field="status" header="Status" />
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={result_per_page}
              totalRecords={totalRecords}
              onPageChange={onPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verification2;
