import { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
// import { FileUpload } from 'primereact/fileupload';
import authFetch from "../axios";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
const NewReg = () => {
  const [searchParams] = useSearchParams();
  const toast = useRef(null);
  const navigate = useNavigate();
  const [state, setStates] = useState([]);
  const goBack = () => navigate(-1);
  const [userState, setUserState] = useState(searchParams.get("state") || "");
  const [userLga, setUserLga] = useState("");
  const [userCenter, setUserCenter] = useState("");
  const [userDcr, setUserDcr] = useState("");

  const [actionLoad, setActionLoad] = useState(false);
  const [userSig, setUserSig] = useState("");
  const [lga, setLga] = useState([]);
  const [center, setCenter] = useState([]);
  const [dcr, setDcr] = useState([]);
  const [nin, setNin] = useState("");
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [selectedPerm, setSelectedPerm] = useState("");
  // const val=['Can_Do_Attestation']
  const changeState = async (e) => {
    setUserState(e.target.value);
    const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    setLga(statz.data);
  };

  const changeLga = async (e) => {
    setUserLga(e.target.value);
    const statz = await authFetch.get(`/option/regcenter/${e.target.value}`);
    // /users/?result_per_page=20&page=1&lga_id=308&role_id=3
    // const dcr = await authFetch.get(`/users/registra/dcr/${e.target.value}`);
    const dcr = await authFetch.get(
      `/users/?result_per_page=20&page=1&lga_id=${e.target.value}&role_id=2`
    );
    // console.log(statz.data.result)
    // setMyStatz(statz.data.mtn);
    // console.log(dcr)
    setCenter(statz?.data);
    setDcr(dcr?.data?.result);
  };

  const customBase64Uploader = async (event) => {
    console.log(event.target.files[0]);
    // // convert file to base64 encoded
    const file = event.target.files[0];
    const reader = new FileReader();
    // let blob = await fetch(file.objectURL).then((r) => r.blob());

    reader.readAsDataURL(file);

    reader.onloadend = function () {
      const base64data = reader.result;
      // console.log(base64data)
      setUserSig(base64data);
    };
  };

  const create = async () => {
    if (!username || !phone || !userSig || !email || !selectedPerm) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `please fill the necessary fields.`,
      });
      return;
    }
    setActionLoad(true);
    const data = {
      UserName: username,
      Email: email,
      Phone_No: phone,
      // Password: pwd,
      NIN: nin,
      State_ID: parseInt(userState),
      LGA_ID: parseInt(userLga),
      Signature: userSig,
      Reg_Center_ID: parseInt(userCenter),
      dcr: parseInt(userDcr),
      Role_ID: 3,
      permissions: selectedPerm.join(","),
      is_active: "Active",
    };

    console.log(data);

    try {
      const statz = await authFetch.post(`/users`, data);

      if (statz.data.success === true) {
        setActionLoad(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message ?? "Record created"}`,
        });
        setEmail("");
        setUserState("");
        setUserLga("");
        setNin("");
        setUsername("");
        setPhone("");
        setEmail("");
        // setPwd('');
        navigate("/registrars-list");
      } else {
        setActionLoad(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${statz.data.message ?? "record not created"}`,
        });
      }
    } catch (error) {
      setActionLoad(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message ?? "record not created"}`,
      });
      console.log(error);
    }
  };

  // const valueTemplate = () => {
  //     const length = selectedPerm ? selectedPerm.length : 0;
  //     return (
  //         <div className="py-2 px-3">
  //             <b>{length}</b> item{length > 1 ? 's' : ''} selected.
  //         </div>
  //     );
  // };

  useEffect(() => {
    const getStates = async () => {
      const statz1 = await authFetch.get(`/option/states/63`);
      const statz2 = await authFetch.get(`/option/permissions`);
      // const statz = await authFetch.get(`/option/lga/${searchParams.get('state')}`);
      // option/permissions
      setStates(statz1.data);
      // setLga(statz.data);
      setPermissions(statz2.data);
    };
    // const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    // console.log(statz.data.result)
    // setMyStatz(statz.data.mtn);

    getStates();
  }, []);

  // console.log(searchParams.get('state'))
  return (
    <div className="grid mt-2 p-4">
      <div className="col-12 lg:col-12">
        <div className="card border-round shadow-2 p-3">
          {/* <div className="card"> */}
          <Button
            label="Go back"
            icon="pi pi-arrow-left"
            className="p-button-sm my-3"
            onClick={goBack}
          />
          <h5 className="border-green-500 border-bottom-3 text-lg font-bold mb-3">
            New Registrar
          </h5>
          <div className="formgrid grid">
            <div className="field col-12 md:col-4">
              <label htmlFor="firstname6">
                username <span className="text-red-500">* required</span>
              </label>

              <InputText
                onChange={(e) => setUsername(e.target.value)}
                keyfilter="alpha"
                maxLength={50}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
              {/* <input id="firstname6" type="text" onChange={(e)=>setUsername(e.target.value)}  className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/> */}
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="lastname8">
                Phone number <span className="text-red-500">* required</span>
              </label>
              <InputText
                onChange={(e) => setPhone(e.target.value)}
                keyfilter="num"
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
              {/* <input id="lastname8" type="text" onChange={(e)=>setPhone(e.target.value)} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" maxLength={11}/> */}
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="lastname7">NIN</label>
              <InputText
                onChange={(e) => setNin(e.target.value)}
                keyfilter={"num"}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="firstname9">
                email <span className="text-red-500">* required</span>
              </label>
              <InputText
                id="firstname9"
                keyfilter={"email"}
                onChange={(e) => setEmail(e.target.value)}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
            </div>
            {/* <div className="field col-12 md:col-4">
            <label htmlFor="lastname6">Password</label>
            <input id="lastname6" type="password" onChange={(e)=>setPwd(e.target.value)} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
        </div> */}
            {/* <div className="field col-12 md:col-4">
        <FileUpload mode="basic" name="demo[]"  accept="image/*" customUpload uploadHandler={customBase64Uploader} />
      </div> */}

            <div className="field col-12 md:col-4">
              <label htmlFor="firstname6">
                upload signature{" "}
                <span className="text-red-500">* required</span>
              </label>
              <input
                type="file"
                onChange={customBase64Uploader}
                className="w-full appearance-none outline-none"
              />
            </div>

            {/* <div className="field col-12 md:col-4">
            <label htmlFor="address">Address</label>
            <textarea id="address" type="text" rows="4" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"></textarea>
        </div> */}
            {/* <div className="field col-12 md:col-4">
            <label htmlFor="city">City</label>
            <input id="city" type="text" className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
        </div> */}
            <div className="field col-12 md:col-4">
              <label htmlFor="state">State</label>
              <select
                id="state"
                value={userState}
                className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                onChange={changeState}
                style={{ appearance: "auto" }}
              >
                <option value={""}>select state</option>
                {state.map((item, i) => {
                  return (
                    <option value={item.State_ID} key={i}>
                      {item.State_Name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="state">Lga</label>
              <select
                id="lga"
                onChange={changeLga}
                className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                style={{ appearance: "auto" }}
              >
                <option value="">select lga</option>
                {lga.map((item, i) => {
                  return (
                    <option value={item.LGA_ID} key={i}>
                      {item.LGA_Name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="state">Center</label>
              <select
                id="cen"
                onChange={(e) => setUserCenter(e.target.value)}
                className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                style={{ appearance: "auto" }}
              >
                <option value="">select center</option>
                {center.map((item, i) => {
                  return (
                    <option value={item.Reg_Center_id} key={i}>
                      {item.Reg_Center_Name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="state">DCR</label>
              <select
                id="dcr"
                onChange={(e) => setUserDcr(e.target.value)}
                className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                style={{ appearance: "auto" }}
              >
                <option value="">select dcr</option>
                {dcr.map((item, i) => {
                  return (
                    <option value={item.User_ID} key={i}>
                      {item.UserName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="field col-12 md:col-12 text-overflow-ellipsis">
              <label>
                Permissions <span className="text-red-500">* required</span>
              </label>
              <MultiSelect
                // value={`selected:${selectedPerm.length}`}
                value={selectedPerm}
                // value={val&& val}
                // valueTemplate={valueTemplate}
                maxSelectedLabels={3}
                onChange={(e) => setSelectedPerm(e.value)}
                options={permissions}
                placeholder="Select user permissions"
                className="w-full"
              />
            </div>

            <div className="field col-12 ">
              <Button
                label="Create Registrar"
                className="p-button-success my-2"
                loading={actionLoad}
                onClick={create}
              />
            </div>
            <Toast ref={toast} position="top-center" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewReg;
