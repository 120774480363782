import { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import authFetch from "../axios";
import { Toast } from "primereact/toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
const NewReg2 = () => {
  const toast = useRef(null);
  const [searchParams] = useSearchParams();
  const [state, setStates] = useState([]);
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [userState, setUserState] = useState(searchParams.get("state") || "");
  const [userLga, setUserLga] = useState("");
  const [nin, setNin] = useState("");
  const [actionLoad, setActionLoad] = useState(false);
  const [username, setUsername] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // const [pwd,setPwd] = useState('');
  const [lga, setLga] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedPerm, setSelectedPerm] = useState("");

  const changeState = async (e) => {
    setUserState(e.target.value);
    const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    // console.log(statz.data.result)
    // setMyStatz(statz.data.mtn);
    setLga(statz.data);
  };
  // const [userState,setUserState] = useState('');

  const create = async () => {
    if (!username || !phone || !email || !selectedPerm) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `please fill the necessary fields.`,
      });
      return;
    }
    setActionLoad(true);
    const data = {
      UserName: username,
      Email: email,
      Phone_No: phone,
      // Password: pwd,
      NIN: nin,
      State_ID: parseInt(userState),
      LGA_ID: parseInt(userLga),
      Role_ID: 2,
      permissions: selectedPerm.join(","),
      is_active: "Active",
    };

    try {
      const statz = await authFetch.post(`/users`, data);
      if (statz.data.success === true) {
        setActionLoad(false);
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: `${statz.data.message}`,
        });
        setEmail("");
        setUserState("");
        setUserLga("");
        setNin("");
        setUsername("");
        setPhone("");
        setEmail("");
        // setPwd('');
        navigate("/dcr-list");
      } else {
        setActionLoad(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${statz.data.message}`,
        });
        // console.log(error);
      }
    } catch (error) {
      setActionLoad(false);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${error.response.data.message}`,
      });
      console.log(error);
    }
  };

  //     useEffect(() => {
  //         const getStates = async()=>{
  //             const statz = await authFetch.get(`/option/states`);
  //             // console.log(statz.data.result)
  //             // setMyStatz(statz.data.mtn);
  //             setStates(statz.data)
  //             // console.log(statz.data.result)
  //                 // setProducts(statz.data.results)
  //                 // setLoading1(false)
  //         }
  //         getStates();

  // }, []);

  useEffect(() => {
    const getStates = async () => {
      const statz1 = await authFetch.get(`/option/states/63`);
      const statz2 = await authFetch.get(`/option/permissions`);
      // const statz = await authFetch.get(`/option/lga/${searchParams.get('state')}`);
      // option/permissions
      setStates(statz1.data);
      // setLga(statz.data);
      setPermissions(statz2.data);
    };
    // const statz = await authFetch.get(`/option/lga/${e.target.value}`);
    // console.log(statz.data.result)
    // setMyStatz(statz.data.mtn);

    getStates();
  }, [searchParams]);
  return (
    <div className="grid mt-2 p-4">
      <div className="col-12 lg:col-12">
        <div className="card border-round shadow-2 p-3">
          {/* <div className="card"> */}
          <Button
            label="Go back"
            icon="pi pi-arrow-left"
            className="p-button-sm my-3"
            onClick={goBack}
          />
          <h5 className="border-green-500 border-bottom-3 text-lg font-bold mb-3">
            New DCR
          </h5>
          <div className="formgrid grid">
            {/* "UserName": "Abel Amos",
    "Email": "abelarmos@email.com",
    "Phone_No":"08134698252",
    "Password": "abelamos123",
    "NIN":"838992019982921",
    "State_ID": 14,
    "LGA_ID": 309 */}
            <div className="field col-12 md:col-4">
              <label htmlFor="firstname6">
                username <span className="text-red-500">* required</span>
              </label>
              <InputText
                id="firstname6"
                keyfilter={"alpha"}
                onChange={(e) => setUsername(e.target.value)}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={50}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="lastname6">
                Phone number <span className="text-red-500">* required</span>
              </label>
              <InputText
                id="lastname6"
                keyfilter={"num"}
                onChange={(e) => setPhone(e.target.value)}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="lastname6">NIN</label>
              <InputText
                id="lastname5"
                keyfilter={"num"}
                onChange={(e) => setNin(e.target.value)}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                maxLength={11}
              />
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="firstname6">
                email <span className="text-red-500">* required</span>
              </label>
              <InputText
                id="firstname2"
                keyfilter={"email"}
                onChange={(e) => setEmail(e.target.value)}
                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
              />
            </div>
            {/* <div className="field col-12 md:col-4">
            <label for="lastname6">Password</label>
            <input id="lastname2" type="password" onChange={(e)=>setPwd(e.target.value)} className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
        </div> */}

            <div className="field col-12 md:col-4">
              <label htmlFor="state">State</label>
              <select
                id="state"
                value={userState}
                className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                onChange={changeState}
                style={{ appearance: "auto" }}
              >
                <option value={""}>select state</option>
                {state.map((item, i) => {
                  return (
                    <option value={item.State_ID} key={i}>
                      {item.State_Name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="field col-12 md:col-4">
              <label htmlFor="lga">LGA</label>
              <select
                id="lga"
                onChange={(e) => setUserLga(e.target.value)}
                className="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                style={{ appearance: "auto" }}
              >
                <option value="">select lga</option>
                {lga.map((item, i) => {
                  return (
                    <option value={item.LGA_ID} key={i}>
                      {item.LGA_Name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="field col-12 md:col-4">
              <label>
                Permission <span className="text-red-500">* required</span>
              </label>
              <MultiSelect
                value={selectedPerm}
                onChange={(e) => setSelectedPerm(e.value)}
                options={permissions}
                placeholder="Select user permissions"
                className="w-full"
                maxSelectedLabels={3}
              />
            </div>
            <div className="field col-12 ">
              <Button
                label="Create DCR"
                className="p-button-success my-2"
                loading={actionLoad}
                onClick={create}
              />
            </div>
            <Toast ref={toast} position="top-center" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewReg2;
