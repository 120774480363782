import { useSelector } from "react-redux";
import { modulePermissions } from "../../pages/ProtectedRouteAdmin";

const useModuleAccess = (permission) => {
  const { userInfo: user } = useSelector((state) => state.user);
  if (typeof permission === "object") {
    return permission.some((perm) => modulePermissions(perm, user));
  } else if (typeof permission === "string") {
    return modulePermissions(permission, user);
  }
  return false;
};

export default useModuleAccess;
