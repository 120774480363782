import { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import {
  getAllAttestationwithoutNinStats,
  getAllAttestationWithoutNINRecords,
} from "../../redux/apiCalls";
// import { Dropdown } from "primereact/dropdown";
import CardWidget from "../../components/widgets/cards";
import { Toast } from "primereact/toast";
import { RegTypeOptions, statusFilterOptions } from "../../helpers/constants";
import FilterHeader from "../../components/FilterHeader";

const AllAttestationWithoutNIN = () => {
  const toast = useRef(null);
  const [statisticsLoading, setStatisticsLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(20);
  const [filters, setFilters] = useState({
    type: "",
    search: "",
  });
  const [pagination, setPagination] = useState([]);

  let pageIndexOffset = 0;
  const pageStartIndex = page > 1 ? (page - 1) * rows : 0;
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1); // PrimeReact uses 0-based page index
    setRows(event.rows);
    pageIndexOffset = event.first;
  };

  const [registrations, setRegistrations] = useState([]);

  const getStatistics = async () => {
    try {
      const data = await getAllAttestationwithoutNinStats();
      setStatistics(data);
      setStatisticsLoading(false);
    } catch (error) {
      console.error(error);
      setStatisticsLoading(false);
    }
  };

  const getRecords = async () => {
    setLoading(true);
    try {
      const data = await getAllAttestationWithoutNINRecords({
        result_per_page: rows,
        page,
        type: filters.type,
        search: filters.search,
      });

      setRegistrations(data?.result);
      setPagination(data?.pagination);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getStatistics();
  }, []);

  useEffect(() => {
    getRecords();
    // eslint-disable-next-line
  }, [filters, page, rows]);

  const stats = [
    {
      name: "Total Modifications",
      count: statistics?.total,
      color: "#FFDC27",
    },
  ];

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`view/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };
  const regTypeFormat = (rowData) => {
    switch (+rowData?.self) {
      case RegTypeOptions.SelfService:
        return <p className={`font-bold`}>Self Service</p>;
      case RegTypeOptions.Assisted:
        return <p className={`font-bold`}>Assisted</p>;
      default:
        return <p className={`font-bold`}>----</p>;
    }
  };

  const nameTemplate = (rowData) => {
    return (
      <p className={`font-bold`}>
        {`${rowData?.surname ?? ""} ${rowData?.first_name ?? ""} ${
          rowData?.middle_name ?? ""
        } `}
      </p>
    );
  };

  const dater = (rowData) => {
    return (
      <p className={`font-bold`}>
        {rowData?.date_created?.split("T")[0] ?? ""}
      </p>
    );
  };

  const SN = (rowData) => {
    const sn = registrations.findIndex((items) => items === rowData);

    return <p>{pageStartIndex + sn + 1 + pageIndexOffset}</p>;
  };

  const filterGroups = [
    { name: "search", label: "Search", type: "search" },
  ];
  const handleFilterChange = (updatedFilters) => {
    setFilters(updatedFilters);
  };

  const renderHeader1 = () => {
    return (
      <>
        <p className="capitalize">
          {filters?.status === "" ? "All" : filters?.status ?? "All"}{" "}
          Registrations -{pagination?.total}
        </p>
        <FilterHeader
          filters={filterGroups}
          onFilterChange={handleFilterChange}
        />
      </>
    );
  };
  const header1 = renderHeader1();
  const dt = useRef(null);

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <div className=" mt-2">
        <div className="">
          <div className="mt-3 w-full m-0 grid gap-4 ">
            {stats.map((item, index) => {
              const { name, color, count } = item;
              return (
                <CardWidget
                  loading={statisticsLoading}
                  key={index}
                  name={name}
                  color={color}
                  count={count}
                />
              );
            })}
          </div>

          <div className="card mt-6 p-3 ">
            <DataTable
              value={registrations}
              ref={dt}
              loading={loading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              id="p-table"
            >
              <Column body={SN} header="S/N"></Column>
              <Column field="Name" body={nameTemplate} header="Name"></Column>
              <Column
                field="attestation_number"
                header="Attestation Number"
              ></Column>

              <Column header="Date registered" body={dater}></Column>
              <Column body={regTypeFormat} header="Registration Type"></Column>
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={first}
              rows={rows}
              totalRecords={pagination?.total}
              rowsPerPageOptions={[10, 20, 50, 100]}
              // page={page}
              onPageChange={onPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllAttestationWithoutNIN;
