import React from "react";

const StatusArticle = ({ color, status }) => {
  return (
    <article className={`${color} flex justify-content-between text-white text-sm p-2`}>
      <span className="text-sm font-bold">Registration Details</span>
      <span className="text-sm font-bold">Status: {status} </span>
    </article>
  );
};

export default StatusArticle;
