import {  useState,useEffect,useRef } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
// import { Dropdown } from 'primereact/dropdown';
import authFetch from '../axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgressSpinner } from 'primereact/progressspinner';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
const SingleHospital = () => {
    const {id} = useParams();
    const toast = useRef(null);
    const navigate=useNavigate();
    const goBack = () => navigate(-1);
    // const cities = [
    //     { name: 'Birth Registrations', code: 'NY' },
    //     { name: 'Death Registrations', code: 'RM' },
    //     { name: 'Birth Registrations & Death Registrations', code: 'LDN' },
        
    // ];
    const [loading,setLoading]= useState(true);
  // const guy=[1,2]
  const [displayBasic2, setDisplayBasic2] = useState(false);
  const [reason, setReason] = useState('');
  const [visibleReason, setVisibleReason] = useState(false);
  const [licenseimg, setLicenceImg] = useState('');
  const [permitimg, setPermitImg] = useState('');
 // eslint-disable-next-line
  const [selectedCity1, setSelectedCity1] = useState(null);
  const [products, setProducts] = useState({});
  const [visible, setVisible] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingApproval, setApprovalLoading] = useState(false);
  
  const [licvisible, setlicVisible] = useState(false);
  const [permissions,setPermissions] = useState([]);
const [selectedPerm,setSelectedPerm] = useState(null);
//   const[city,setCity] = useState();
// eslint-disable-next-line
  const onCityChange = (e) => {
    setSelectedCity1(e.value);
}
      const dialogFuncMap = {
         
          'displayBasic2': setDisplayBasic2,
         
      }
  
      const onClick = (name, position) => {
          dialogFuncMap[`${name}`](true);
  
         
      }
  
  

      const onHide = (name) => {
          dialogFuncMap[`${name}`](false);
      }
  
      const create=async(nm,upd='false')=>{

        const data = {

            
            account_id: parseInt(id),
           permissions:selectedPerm?.join(",") || null,
        }
        setApprovalLoading(true)
        try {
            const statz = await authFetch.patch(upd==='true' ? `/hospital` : `/hospital/approve`,
            data);

            if(statz.data.success === true){
                toast.current.show({ severity: 'success', summary: 'Success', detail: `${statz.data.message}` });
                setApprovalLoading(false)
            // setPwd(''); 
            // navigate('/')
             window.location.reload()
            }
        }
              
        catch (error) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: `${error.response.data.message}` });
            setApprovalLoading(false)
            console.log(error);
        }
        
        onHide(nm)
        }

        const reject=async()=>{
if(!reason){
alert('please put a reason');
return;
}

setLoadingReject(true)
            const data = {
                account_id: parseInt(id),
                reason
            }
            
            try {
                const statz = await authFetch.patch(`/hospital/reject`,data);
    
                if(statz.data.success === true){
                    toast.current.show({ severity: 'success', summary: 'Success', detail: `${statz.data.message}` });
                    setLoadingReject(false)
                // setPwd(''); 
                // navigate('/')
                 window.location.reload()
                }
             
            }
                  
            catch (error) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: `${error.response.data.message}` });
                setLoadingReject(false)
                console.log(error);
            }
            
        
            
            }

      
      const renderFooter = (name) => {
          return (
              <div className='flex justify-content-between'>
                  <Button label="Close" icon="pi pi-times" onClick={() => onHide(name)} className="p-button-text" />
                  {
                    products?.status!=='approved'&&
                  <Button label="Approve" icon="pi pi-check" loading={loadingApproval} onClick={() => create(name)} className="p-button-success" />
      }
                  {
                    products?.status==='approved'&&
                  <Button label="Update" icon="pi pi-check" onClick={() => create(name,'true')} loading={loadingApproval} className="p-button-success" />
      }
              </div>
          );
      }


      const viewLicense=async (url)=>{
        const {data} = await authFetch.get(`/get-preview-url/${url}`);
setLicenceImg(data.url)
setlicVisible(true)
      }

      const viewPermit=async (url)=>{
        const {data} = await authFetch.get(`/get-preview-url/${url}`);
setPermitImg(data.url)
setVisible(true)
}


      useEffect(() => {
        const getDatz=async ()=>{
    const statz1 = await authFetch.get(`/hospital/single/${id}`);
    const statz2 = await authFetch.get(`/option/permissions`);
   
    setPermissions(statz2.data);
    setProducts(statz1.data)
    setLoading(false);
}
 getDatz();       
    }, [id]);



    if(loading){
        return <div className='flex justify-content-center align-items-center'>
        <ProgressSpinner style={{width:'3rem',height:'3rem'}}/>
        </div>
        }
  return (
    <div>

<div className="surface-card p-4 shadow-2 border-round my-2">
<Button label="Go back" icon="pi pi-arrow-left" className="p-button-sm w-2 my-2" onClick={goBack}  />
        <div className='flex align-content-center justify-content-between'>
<div className="text-900 font-bold ">{products?.name}</div>
 <div className="flex align-content-center  md:flex-wrap py-2 gap-4 justify-content-end	 ">
 <Button label="View license" severity="secondary" text  onClick={()=>viewLicense(products?.license)} />
<Button label="View permit" severity="success" text onClick={()=>viewPermit(products?.permit)} />
</div>
 </div>

        <div className="flex flex-column md:flex-row md:flex-wrap pt-2 border-top-1 border-bottom-1 border-green-300">
          {/* <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Particulars of child</div> */}
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">CAC Number</div>
            <div className="text-900">{products?.cac_number}</div>
            
        </div>

        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Hospital Type</div>
            <div className="text-900">{products?.type}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Hospital Category</div>
            <div className="text-900">{products?.category}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Contact Person's Phone Number</div>
            <div className="text-900">{products?.contact_phone}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
        <div className="col-12 md:col-4 p-3">
            <div className="text-500 font-medium mb-2">Contact Person's Email Address</div>
            <div className="text-900">{products?.contact_email}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
      
      
       
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">State</div>
            <div className="text-900">{products?.state?.State_Name}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">LGA</div>
            <div className="text-900">{products?.lga?.LGA_Name}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>

        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Status</div>
            <div className="text-900">{products?.status??'Pending'}</div>
            {/* <div className="text-900">Elliot Alderson</div> */}
        </div>

      
      
       
    </div>

   
    {/* <div className="grid grid-nogutter pt-2 border-top-1 border-bottom-1 border-green-300">
          <div className='col-12 p-3 uppercase border-bottom-1 border-green-300 text-md font-bold '>Particulars of informant</div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
            
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
          
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
           
        </div>
        <div className="col-12 md:col-2 p-3">
            <div className="text-500 font-medium mb-2">Name</div>
            <div className="text-900">Delta</div>
            
        </div>
      
       
       
    </div> */}
    <div className='flex gap-3 flex-column md:flex-row'>
{products?.status==='pending'?
<>
    <Button label="Approve" className="p-button-success my-2"onClick={() => onClick('displayBasic2')} />
    <Button label="Reject" className="p-button-warning my-2" onClick={() => setVisibleReason(true)} />
    </>
    :''}

{products?.status==='approved'?
<>
    <Button label="Update" className="p-button-success my-2"onClick={() => onClick('displayBasic2')} />
    <Button label="Reject" className="p-button-warning my-2" onClick={() => setVisibleReason(true)} />
    </>
    :''}

{products?.status==='rejected'?
<>
    <Button label="Approve" className="p-button-success my-2"onClick={() => onClick('displayBasic2')} />
    </>
    :''}
    </div>
  
</div>

<Dialog header="Reason" visible={visibleReason}  style={{ width: '30vw' }} onHide={() => setVisibleReason(false)}>
<InputTextarea value={reason} onChange={(e) => setReason(e.target.value)} rows={5} cols={30} />
<Button label="Reject" className="p-button-warning my-2 w-full" loading={loadingReject} onClick={() => reject()} />
</Dialog>
<Dialog header="Assign Role" visible={displayBasic2} style={{ width: '30vw',color:'#565656' }} breakpoints={{'960px': '95vw'}} footer={renderFooter('displayBasic2')} onHide={() => onHide('displayBasic2')}>
                <div className="flex">  
                <div className='w-full px-4'>
                <MultiSelect
         maxSelectedLabels={10}
        value={selectedPerm}
        // value={val&& val}
        onChange={(e) => setSelectedPerm(e.value)} options={permissions} 
 placeholder="Select user permissions"  className="w-full" />
                {/* <Dropdown value={selectedCity1} options={cities} onChange={onCityChange} style={{width:'100%'}} optionLabel="name" placeholder="Select Role" /> */}
            

                </div>
                   {/* <div className='w-2'>
                   <div className="flex relative align-items-center justify-content-center mx-1 py-3 min-h-full">
            <div className="border-left-1 border-300 top-0 left-50 absolute h-full"></div>
            <div className="py-2 z-1 surface-0">
                <span className="text-900 font-medium">OR</span>
            </div>
                    </div>
                   </div> */}
                 
                   

                    {/* <div className='w-5'>
                    <div className="field mb-4 col-12 md:col-6">
                    <div className="text-sm text-900 mb-2">Employee Tax ID</div>
            <label htmlFor="company_name" className="text-sm text-900 ">Input employee Tax Id to continue</label>
            <InputText id="company_name" type="text" className='' placeholder='Input employee Tax Id' />
        </div>
        <Button label="Continue" className="w-8 mb-7 md:mb-0"  />
    </div> */}
   </div>
                </Dialog>

                <Dialog header="Certificate" visible={visible} style={{ width: '70vw' }} onHide={() => setVisible(false)}>
                <img  src={permitimg} className='w-full' alt=''/>
            </Dialog>

            <Dialog header="Certificate" visible={licvisible} style={{ width: '70vw' }} onHide={() => setlicVisible(false)}>
                <img  src={licenseimg} className='w-full' alt=''/>
            </Dialog>
            <Toast ref={toast} position="top-center"  />
    </div>
  )
}

export default SingleHospital