import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import authFetch from "../axios";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { Dropdown } from "primereact/dropdown";
import RecordDetails from "./ApprovalAccess/recordDetails";
import { get_state_by_id, reverse_country } from "../helpers/world.helper";
import { InputTextarea } from "primereact/inputtextarea";
import toast from "react-hot-toast";
import ErrorMessageExtractor from "../helpers/utils/errorHandler";
import SupportingDocuments from "../components/SupportingDocument";
import {
  OrganizationVerificationLevels,
  OrganizationVerificationStatus,
} from "../helpers/constants";
import { getLevelsDetailsText } from "../utils";
const SingleApprovedOrg = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [loading, setLoading] = useState(false);
  const [rejectedVisible, setRejectedVisible] = useState(false);
  const [rejectReasons, setRejectReasons] = useState("");
  const [isRejectReasons, setIsRejectReasons] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [record, setRecords] = useState({});
  const [selectedLevel, setSelectedLevel] = useState(null);
  const permissions = [
    {
      name: "level 1",
      value: OrganizationVerificationLevels.level1,
    },
    {
      name: "level 2",
      value: OrganizationVerificationLevels.level2,
    },
    {
      name: "level 3",
      value: OrganizationVerificationLevels.level3,
    },
    {
      name: "level 4",
      value: OrganizationVerificationLevels.level4,
    },
    {
      name: "level 5",
      value: OrganizationVerificationLevels.level5,
    },
  ];

  const rejectRecord = async () => {
    if (rejectReasons.length < 1)
      return toast.error("reject reasons cannot be empty");

    const payload = {
      id: Number(id),
      status: OrganizationVerificationStatus.rejected,
      verification_level: selectedLevel,
      reason: rejectReasons,
    };

    try {
      const { data } = await authFetch.patch(
        `/verification/user/change-status`,
        payload
      );

      if (data.success === true) {
        toast.success(data.message);
        window.location.reload();
      }
    } catch (error) {
      ErrorMessageExtractor.errorParser(error);
    }
  };

  const approve = async () => {
    if (!selectedLevel)
      return toast.error("please select a level of verification");
    setSubmitLoading(true);
    const payload = {
      id: Number(id),
      status: OrganizationVerificationStatus.approved,
      verification_level: selectedLevel,
    };

    try {
      const { data } = await authFetch.patch(
        `/verification/user/change-status`,
        payload
      );

      if (data.success === true) {
        toast.success(data.message);
        setTimeout(() => {
          setApproveSuccess(!approveSuccess);
        }, 1500);
      }
    } catch (error) {
      ErrorMessageExtractor.errorParser(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const getOrganizationRecordById = async () => {
      setLoading(true);
      try {
        const { data } = await authFetch.get(`/verification/user/single/${id}`);
        setRecords(data.data);
      } catch (error) {
        ErrorMessageExtractor.errorParser(error);
      } finally {
        setLoading(false);
      }
    };
    getOrganizationRecordById();
  }, [id, approveSuccess]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }
  return (
    <div>
      <div>
        <div>
          <Button
            label="Go back"
            icon="pi pi-arrow-left"
            className="p-button-sm my-2"
            onClick={goBack}
          />
        </div>
        <div className="flex flex-column gap-5">
          <article
            className={`${
              record?.user?.status === OrganizationVerificationStatus.approved
                ? "bg-green-600"
                : "bg-yellow-600"
            } text-dark text-sm p-2`}
          >
            <span className="text-sm text-white font-bold">
              Organization Details
            </span>
          </article>

          <RecordDetails
            type="organization"
            records={record?.user}
            fieldsToDisplay={[
              "name_of_organization",
              "organization_type",
              "phone_number",
              "email_address",
              "address",
              "website(optional)",
              "country",
              "state",
              "city",
              "zip_code",
              "verification_level",
            ]}
            fieldProcessors={{
              name_of_organization: (records) =>
                `${records?.organization_name ?? "----"}`,
              verification_level: (records) =>
                `${records?.verification_level ?? "----"}`,
              organization_type: (records) =>
                `${records?.organization_type ?? "----"}`,
              phone_number: (records) =>
                `${records?.organization_phone ?? "----"}`,
              email_address: (records) =>
                `${records?.organization_email ?? "----"}`,
              address: (records) =>
                `${records?.organization_address ?? "----"}`,
              state: (records) =>
                `${
                  records?.organization_state
                    ? get_state_by_id(+records?.organization_state)
                    : "----"
                }`,
              city: (records) => `${records?.organization_city ?? "----"}`,
              country: (records) =>
                `${
                  records?.organization_country
                    ? reverse_country(records?.organization_country)
                    : "----"
                }`,
              website: (records) =>
                `${records?.organization_website ?? "----"}`,
              zip_code: (records) =>
                `${records?.organization_zip_code ?? "----"}`,
            }}
          />

          <RecordDetails
            type="contact person"
            records={record?.user}
            fieldsToDisplay={["name", "title", "email_address"]}
            fieldProcessors={{
              name: (records) => `${records?.contact_person_name ?? "----"}`,
              email_address: (records) =>
                `${records?.contact_person_email ?? "----"}`,
              title: (records) => `${records?.contact_person_title ?? "----"}`,
            }}
          />

          <article className="shadow-lg bg-white  text-sm box-shadow-css">
            <div className=" px-4 py-2">
              <h2 className="text-sm text-black-alpha-90 font-bold">
                <span className="border-bottom-2 border-blue-600">
                  Uploaded{" "}
                </span>{" "}
                Documents
              </h2>
              <SupportingDocuments
                SupportingDocumentArray={record?.user?.SupportingDocument}
              />
            </div>

            {record?.user?.status !==
              OrganizationVerificationStatus.approved && (
              <div className="mt-6">
                <div className="border-y-2">
                  <p className=" divide-y-2 px-4 py-">
                    ASSIGN LEVEL
                    <span className="border-left-2 px-2	ml-2">
                      Organizations must be assigned level before approval
                    </span>
                  </p>
                </div>
                <div className="p-4">
                  <div className=" md:w-3 ">
                    <label htmlFor="level" className="py-4">
                      Select Level
                    </label>
                    <Dropdown
                      id="level"
                      value={selectedLevel}
                      options={permissions}
                      className="w-full border-1 border-green-700 "
                      onChange={(e) => {
                        setSelectedLevel(e.target.value);
                      }}
                      placeholder={`Select Level`}
                      optionLabel={"name"}
                      optionValue={"value"}
                    />
                  </div>

                  <p>
                    {selectedLevel
                      ? `Level Contains: ${getLevelsDetailsText(selectedLevel)}`
                      : ""}
                  </p>
                </div>
              </div>
            )}
          </article>
        </div>
      </div>
      <div className="mt-6 flex gap-1 md:gap-3 flex-column justify-content-end md:flex-row">
        {record?.user?.status !== OrganizationVerificationStatus.approved ? (
          <Button
            label="Approve"
            className="p-button-success my-2"
            onClick={approve}
            loading={submitLoading}
          />
        ) : (
          ""
        )}
        <Button
          label="Decline"
          className="p-button-danger my-2"
          onClick={() => {
            setRejectedVisible(true);
          }}
        />
      </div>

      <Dialog
        header="NOTE"
        visible={rejectedVisible}
        className="w-11 md:w-4"
        onHide={() => setRejectedVisible(false)}
      >
        {isRejectReasons ? (
          <>
            <InputTextarea
              value={rejectReasons}
              placeholder="Enter reason for rejecting this record"
              onChange={(e) => setRejectReasons(e.target.value)}
              rows={5}
              className="w-full"
            />{" "}
            <div className="w-full flex gap-3 justify-content-between">
              <Button
                label="SUBMIT"
                onClick={rejectRecord}
                loading={submitLoading}
                className="w-full p-button-danger my-2"
              />
              <Button
                label="CANCEL"
                onClick={() => {
                  setRejectedVisible(false);
                }}
                className=" w-full bg-transparent text-primary my-2"
              />
            </div>
          </>
        ) : (
          <>
            <p>Are you sure you want to reject this registration?</p>
            <div className="w-full flex gap-3 justify-content-between">
              <Button
                label="YES"
                className="w-full p-button-danger my-2"
                onClick={() => {
                  setIsRejectReasons(true);
                }}
              />
              <Button
                label="NO"
                onClick={() => {
                  setRejectedVisible(false);
                }}
                className=" w-full bg-transparent text-primary my-2"
              />
            </div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default SingleApprovedOrg;
