import { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "../../pages/admin.css";
import authFetch from "../../axios";
import Loading from ".././Loading";

const PublicChart = ({ year }) => {
  const [chartData, setChartData] = useState({});
  const [load, setLoad] = useState(true);
  // eslint-disable-next-line
  const [dats, setDats] = useState();

  let basicOptions = {
    aspectRatio: 2,
    maintainAspectRatio: false,
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          usePointStyle: true,
          boxWidth: 9, // Set the box width for legend labels
          borderRadius: 10, // Set the border radius for legend labels
        },
      },
    },
  };

  useEffect(() => {
    const chart = () => {
      const params = {
        period: year,
        hospitalType: "public",
      };
      let birth;
      let death;

      authFetch
        .get(`/hospital/stats/hospital-stats-graph`, { params })
        .then((res) => {
          console.log(res.data);
          setLoad(false);
          setDats(res.data);
          birth = res.data[0]?.data;
          death = res.data[1].data;

          setChartData({
            labels: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            datasets: [
              {
                label: "Birth Registration",
                data: birth,
                backgroundColor: "#36B048",
                borderRadius: 30,
                barThickness: 8,
              },
              {
                label: "Death Registration",
                backgroundColor: "#6CDEFF",
                data: death,
                borderRadius: 30,
                barThickness: 8,
              },
            ],
          });
        })
        .catch((err) => {
          console.log(err);
        });
    };
    chart();
  }, [year]);
  if (load) {
    return <Loading />;
  }
  return <Chart type="bar" data={chartData} options={basicOptions} />;
};

export default PublicChart;
