import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  ApproveBirthAttestationModificationApi,
  QueryBirthAttestationModficationApi,
  getSingleAttestationModification,
} from "../../redux/apiCalls";
import { Button } from "primereact/button";
import {
  modificationStatus,
  getDetailsForModificationType,
  getModBgcolor,
} from "../../utils";
import ApprovalActions from "./ApprovalActions";
import RecordDetails from "./recordDetails";

const ViewSingleAttestationModification = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [records, setRecords] = useState([]);
  const [color, setColor] = useState("");

  const getSingleRecord = async () => {
    setLoading(true);
    try {
      const response = await getSingleAttestationModification(id);
      setLoading(false);
      setRecords(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, [id, approveSuccess]);

  useEffect(() => {
    const color = getModBgcolor(+records?.mod_status);
    setColor(color);
  }, [records, approveSuccess]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="flex flex-column gap-5">
        <article className={`${color} text-white text-sm p-2`}>
          <span className="text-sm font-bold">Modification Details</span>
        </article>

        <RecordDetails
          type="Applicant"
          records={records}
          fieldsToDisplay={["surname", "first_name", "middle_name"]}
          fieldProcessors={{
            surname: (records) => `${records?.applicant_surname ?? "----"}`,
            first_name: (records) =>
              `${records?.applicant_firstname ?? "----"}`,
            middle_name: (records) =>
              `${records?.applicant_middlename ?? "----"}`,
          }}
        />

        <RecordDetails
          records={records}
          fieldsToDisplay={[
            "modification_type",
            "field_change",
            "reasons_for_modification",
            "comment",
            "Previous_Record",
            "Modified_record",
          ]}
          fieldProcessors={{
            modification_type: (records) =>
              `${records?.mod_type?.replace(/_/g, " ") ?? "----"}`,
            field_change: (records) =>
              `${records?.mod_field?.replace(/_/g, " ") ?? "----"}`,
            reasons_for_modification: (records) =>
              `${records?.mod_reason ?? "----"}`,
            Previous_Record: (records) =>
              `${
                getDetailsForModificationType(records?.mod_field, records)
                  .OldDetails
              }`,
            Modified_record: (records) =>
              `${
                getDetailsForModificationType(records?.mod_field, records)
                  .newDetails
              }`,
          }}
        />
      </div>

      <ApprovalActions
        id={id}
        records={records}
        setApproveSuccess={setApproveSuccess}
        approveSuccess={approveSuccess}
        approveApi={ApproveBirthAttestationModificationApi}
        queryApi={QueryBirthAttestationModficationApi}
        ApprovalStatus={+records?.mod_status}
        approvalStatusType={modificationStatus}
      />
    </div>
  );
};

export default ViewSingleAttestationModification;
