import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import { getSingleBirthNotification } from "../../redux/apiCalls";
import { Button } from "primereact/button";
import { getBgcolor, reverse_option_value } from "../../utils";
import { Toast } from "primereact/toast";
import { sexType } from "../../helpers/constants";
import { Image } from "primereact/image";
import RecordDetails from "./recordDetails";

const ViewSingleBirthNotification = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [color, setColor] = useState("");

  const getSingleRecord = async () => {
    setLoading(true);
    try {
      const response = await getSingleBirthNotification(id);
      setLoading(false);
      setRecords(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, [id]);

  useEffect(() => {
    const color = getBgcolor(+records?.approved_status);
    setColor(color);
  }, [records]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="flex flex-column gap-5">
        <article className={`${color} text-white text-sm p-2`}>
          <span className="text-sm font-bold">Registration Details</span>
        </article>

        <RecordDetails
          type="child"
          records={records}
          fieldsToDisplay={[
            "surname_of_child",
            "first_name_Of_Child",
            "middle_Name_Of_Child",
            "date_of_birh",
            "type_of_birth",
            "birth_order",
            "sex_of_child",
            "place_of_occurence",
            "country_of_Birth",
            "state_of_Birth",
            "face",
          ]}
          fieldProcessors={{
            surname_of_child: (records) =>
              `${records?.childData?.surname ?? ""}`,
            first_name_Of_Child: (records) =>
              `${records?.childData?.firstname ?? "----"}`,
            middle_Name_Of_Child: (records) =>
              `${records?.childData?.middle_name ?? "----"}`,
            date_of_birh: (records) =>
              `${records?.childData?.birth_date ?? "----"}`,
            type_of_birth: (records) =>
              `${records?.birth_typeData?.Description ?? "----"}`,
            birth_order: (records) =>
              `${records?.birth_OrderData?.Desc ?? "----"}`,
            sex_of_child: (records) =>
              `${
                records?.childData
                  ? reverse_option_value(+records?.childData?.gender, sexType)
                  : "----"
              }`,
            place_of_occurence: (records) =>
              `${records?.birth_placeData?.BirthPlace_Desc ?? "----"}`,
            country_of_Birth: (records) =>
              `${
                records?.childData?.birth_countryData?.Country_Name ?? "----"
              }`,
            state_of_Birth: (records) =>
              `${records?.childData?.birth_stateData?.State_Name ?? "----"}`,
            face: (records) => {
              return (
                <>
                  {records?.ChildFace ? (
                    <Image
                      src={records?.ChildFace}
                      zoomSrc={records?.ChildFace}
                      alt={records.id}
                      preview
                      width="100%"
                      height="150px"
                    />
                  ) : (
                    "----"
                  )}
                </>
              );
            },
          }}
        />

        <RecordDetails
          type="mother"
          records={records}
          fieldsToDisplay={[
            "surname",
            "other_names",
            "Marital_Status",
            "Nationality",
            "State_of_Origin",
            "Lga_of_Origin",
            "Ethnic_Group",
            "Literacy",
            "Level_Of_Education",
            "Occupation",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.motherData?.surname ?? ""}`,
            other_names: (records) =>
              `${records?.motherData?.firstname ?? ""} ${
                records?.motherData?.middle_name ?? ""
              }`,
            Marital_Status: (records) =>
              `${
                records?.motherData?.current_marital_statusData?.Status_Desc ??
                "----"
              }`,
            Nationality: (records) =>
              `${
                records?.motherData?.current_nationalityData?.Country_Name ??
                "----"
              }`,
            State_of_Origin: (records) =>
              `${records?.motherData?.origin_stateData?.State_Name ?? "----"}`,
            Lga_of_Origin: (records) =>
              `${records?.motherData?.origin_lgaData?.LGA_Name ?? "----"}`,
            Ethnic_Group: (records) =>
              `${
                records?.motherData?.ethnic_groupData?.Ethnic_Grp_Name ?? "----"
              }`,
            Literacy: (records) =>
              `${
                records?.motherData?.current_literacy_levelData?.Literacy ??
                "----"
              }`,
            Level_Of_Education: (records) =>
              `${
                records?.motherData?.current_education_levelData?.Description ??
                "----"
              }`,
            Occupation: (records) =>
              `${
                records?.motherData?.current_occupationData?.Occupation ??
                "----"
              }`,
            Phone_Number: (records) =>
              `${records?.motherData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.motherData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.motherData?.current_address ?? "----"}`,
          }}
        />

        <RecordDetails
          type="father"
          records={records}
          fieldsToDisplay={[
            "surname",
            "other_names",
            "Marital_Status",
            "Nationality",
            "State_of_Origin",
            "Lga_of_Origin",
            "Ethnic_Group",
            "Literacy",
            "Level_Of_Education",
            "Occupation",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.fatherData?.surname ?? ""}`,
            other_names: (records) =>
              `${records?.fatherData?.firstname ?? ""} ${
                records?.fatherData?.middle_name ?? ""
              }`,
            Marital_Status: (records) =>
              `${
                records?.fatherData?.current_marital_statusData?.Status_Desc ??
                "----"
              }`,
            Nationality: (records) =>
              `${
                records?.fatherData?.current_nationalityData?.Country_Name ??
                "----"
              }`,
            State_of_Origin: (records) =>
              `${records?.fatherData?.origin_stateData?.State_Name ?? "----"}`,
            Lga_of_Origin: (records) =>
              `${records?.fatherData?.origin_lgaData?.LGA_Name ?? "----"}`,
            Ethnic_Group: (records) =>
              `${
                records?.fatherData?.ethnic_groupData?.Ethnic_Grp_Name ?? "----"
              }`,
            Literacy: (records) =>
              `${
                records?.fatherData?.current_literacy_levelData?.Literacy ??
                "----"
              }`,
            Level_Of_Education: (records) =>
              `${
                records?.fatherData?.current_education_levelData?.Description ??
                "----"
              }`,
            Occupation: (records) =>
              `${
                records?.fatherData?.current_occupationData?.Occupation ??
                "----"
              }`,
            Phone_Number: (records) =>
              `${records?.fatherData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.fatherData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.fatherData?.current_address ?? "----"}`,
          }}
        />

        <RecordDetails
          type="informant"
          records={records}
          fieldsToDisplay={[
            "surname",
            "other_names",
            "country_of_residence",
            "state_of_residence",
            "lga_of_residence",
            "Phone_Number",
            "NIN",
            "Street_Address",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.informantData?.surname ?? ""}`,
            other_names: (records) =>
              `${records?.informantData?.firstname ?? ""} ${
                records?.informantData?.middle_name ?? ""
              }`,
            country_of_residence: (records) =>
              `${
                records?.informantData?.current_residence_countryData
                  ?.Country_Name ?? "----"
              }`,
            state_of_residence: (records) =>
              `${
                records?.informantData?.current_residence_stateData
                  ?.State_Name ?? "----"
              }`,
            lga_of_residence: (records) =>
              `${
                records?.informantData?.current_residence_lgaData?.LGA_Name ??
                "----"
              }`,
            Phone_Number: (records) =>
              `${records?.informantData?.current_phone_number ?? "----"}`,
            NIN: (records) => `${records?.informantData?.nin ?? "----"}`,
            Street_Address: (records) =>
              `${records?.informantData?.current_address ?? "----"}`,
          }}
        />

        <article className="shadow-lg bg-white  text-sm px-4 py-2 box-shadow-css">
          <h2 className="text-sm text-black-alpha-90 font-bold">
            <span className="border-bottom-2 border-blue-600">Uploaded </span>{" "}
            Documents
          </h2>
          <section
            className="flex flex-wrap gap-3 w-full"
            style={{ wordBreak: "break-word" }}
          >
            {records?.supporting_document ? (
              <div className="w-full shadow-lg rounded-md overflow-hidden">
                <Image
                  src={records?.supporting_document}
                  zoomSrc={records?.supporting_document}
                  alt="supporting document"
                  preview
                  width="100%"
                  height="300px"
                />
              </div>
            ) : (
              <p>No supporting documents available.</p>
            )}
          </section>
        </article>
      </div>
      <Toast ref={toast} position="top-center" />
    </div>
  );
};

export default ViewSingleBirthNotification;
