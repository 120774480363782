import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { Paginator } from "primereact/paginator";
import { Tooltip } from "primereact/tooltip";
import { useNavigate } from "react-router-dom";
import { getGender } from "../redux/apiCalls";
import authFetch from "../axios";
import { SerialNumberTemplate } from "../utils";
import { useTableSerial } from "../helpers/hooks/useTableSerial";
import { useDebounce } from "../helpers/hooks/useDebounce";

const FnameTemplate = (rowData) => {
  // return rowData.map((item,i)=>{
  return (
    <p className={`font-bold`}>{rowData?.deceasedData?.firstname ?? ""}</p>
  );
  // })
};
const LnameTemplate = (rowData) => {
  // return rowData.map((item,i)=>{
  return <p className={`font-bold`}>{rowData?.deceasedData?.surname ?? ""}</p>;
  // })
};
const ModDeath = () => {
  // const dispatch = useDispatch();
  const [basicFirst, setBasicFirst] = useState(0);

  const [regs, setReg] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  // eslint-disable-next-line
  const [resPage, setResPage] = useState(20);
  const [page, setPage] = useState(1);
  const [stateID, setStateID] = useState(14);
  const [numPages, setNumPages] = useState(0);
  // eslint-disable-next-line
  const [basicRows, setBasicRows] = useState(20);

  const onBasicPageChange = (event) => {
    // console.log(event)
    setBasicFirst(event.first);
    // setBasicRows(event.rows);
    setPage(event.page + 1);
  };

  const clearFilter1 = () => {
    setSearch("");
    setStateID(14);
  };
  const { value: debouncedValue } = useDebounce(search, 1000);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  useEffect(() => {
    const getReg = async (debouncedValue, resPage, page, stateID) => {
      setLoading(true);
      try {
        const res = await authFetch.get(
          `/modification/death-registration/list-by-state?search=${debouncedValue}&result_per_page=${resPage}&page=${page}&StateID=${stateID}`
        );
        const res2 = await authFetch.get(`/option/states/63`);
        setLoading(false);
        setReg(res.data.result);
        setStates(res2.data);
        setNumPages(res.data.pagination.total);
        console.log(res.data);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    getReg(debouncedValue, resPage, page, stateID);
  }, [debouncedValue, resPage, page, stateID]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      setPage(1);
    }, [debouncedValue, stateID]);

  const statusBodyTemplate2 = (rowData) => {
    return (
      <Link
        className={`btn btn-primary text-primary font-bold`}
        to={`/single-moddeath/${rowData.id}`}
      >
        VIEW
      </Link>
    );
  };
  const genderTemplate = (rowData) => {
    return (
      <p className={`font-bold`}>
        {getGender(rowData.deceasedData?.gender ?? "")}
      </p>
    );
  };

  const dateTemplate = (rowData) => {
    return (
      <p className={`font-bold`}>
        {new Date(rowData.date_modified).toLocaleString() ?? ""}
      </p>
    );
  };
  const renderHeader1 = () => {
    return (
      <div className=" grid ">
        <div className="col-12 md:col-4">
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Keyword Search"
          />
        </div>

        <div className="col-12 md:col-8">
          <Dropdown
            value={stateID}
            options={states && states}
            onChange={(e) => setStateID(e.value)}
            placeholder="Select State"
            optionValue="State_ID"
            optionLabel="State_Name"
          />
        </div>
        <div className="col-12 md:col-8">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Clear"
            className="p-button-outlined"
            onClick={clearFilter1}
          />
        </div>
      </div>
    );
  };

  const header1 = renderHeader1();
  const dt = useRef(null);

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        // Get the DataTable container
        const dataTableContainer = document.getElementById("p-table");

        if (dataTableContainer) {
          // Clone the container to avoid modifying the original content
          const clonedContainer = dataTableContainer.cloneNode(true);

          // Check if the cloned container has a table inside
          const table = clonedContainer.querySelector("table");

          if (table) {
            // Remove undesired elements from the cloned container
            const spansToRemove = clonedContainer.querySelectorAll("td span");
            spansToRemove.forEach((span) => {
              span.parentNode.removeChild(span);
            });

            // Generate PDF using the modified container
            doc.autoTable({ html: table });
            doc.save("modify-death.pdf");
          } else {
            console.error("Table element not found in cloned container");
          }
        } else {
          console.error("DataTable container not found");
        }
      });
    });
  };

  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      // Find the table element by class name
      var table_elt = document.querySelector(".p-datatable-table");

      // Check if the table element is found
      if (table_elt) {
        // Clone the table to avoid modifying the original
        const clonedContainer = table_elt.cloneNode(true);

        // Remove <span> elements within <td> elements
        const spansToRemove = clonedContainer.querySelectorAll("td span");
        spansToRemove.forEach((span) => {
          span.parentNode.removeChild(span);
        });

        // Convert the modified table to a worksheet
        const worksheet = xlsx.utils.table_to_sheet(clonedContainer);

        // Create a workbook
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };

        // Convert the workbook to Excel format
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });

        // Save the Excel file
        saveAsExcelFile(excelBuffer, "modified-death");
      } else {
        console.error("Table element not found");
      }
    });
  };
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

   const { serials } = useTableSerial({
     data: regs,
     currentPage: page,
     pageSize: resPage,
   });

  return (
    <>
      <div className="grid mt-2">
        <div className="col-12 lg:col-12">
          <div className="card border-round shadow-2 p-3 ">
            <span className="text-xl font-medium text-900">
              Registration count:{regs.length}
            </span>
            <div className="mb-3 flex align-items-center justify-content-between p-3">
              <Button
                label="Go back"
                icon="pi pi-arrow-left"
                className="p-button-sm"
                onClick={goBack}
              />
              <span className="text-xl font-medium text-900">
                Modified Death Registration List
              </span>
              <div className="flex align-items-center export-buttons">
                <Button
                  type="button"
                  icon="pi pi-file-excel"
                  onClick={exportExcel}
                  className="p-button-success mr-2"
                  data-pr-tooltip="XLS"
                />
                <Button
                  type="button"
                  icon="pi pi-file-pdf"
                  onClick={exportPdf}
                  className="p-button-warning mr-2"
                  data-pr-tooltip="PDF"
                />
              </div>
            </div>
            <DataTable
              value={regs}
              ref={dt}
              loading={loading}
              stripedRows
              responsiveLayout="stack"
              header={header1}
              id="p-table"
            >
              <Column
                field="id"
                header="S/N"
                body={(cell) => SerialNumberTemplate(cell, serials, regs)}
              ></Column>
              <Column header="firstname" body={FnameTemplate}></Column>
              <Column header="SurName" body={LnameTemplate}></Column>
              <Column
                field="death_certificate_number"
                header="Certificate Number"
              ></Column>
              <Column field="" header="Gender" body={genderTemplate}></Column>
              <Column body={dateTemplate} header="Date modified"></Column>
              <Column field="" header="Action" body={statusBodyTemplate2} />
            </DataTable>
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Paginator
              first={basicFirst}
              rows={basicRows}
              totalRecords={numPages}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModDeath;
