import { useEffect } from "react";

import { Card } from "primereact/card";
// import { Link } from 'react-router-dom';
// import { Button } from 'primereact/button';

const Unauthorized = () => {
  useEffect(() => {
    // https://ecrvs-v2-test.onrender.com
    window.location.replace(`${process.env.REACT_APP_HOST}/auth/logout`);
  }, []);

  return (
    <div className="flex justify-content-center align-items-center min-h-screen ">
      <Card title="UNAUTHORIZED">
        <p className="m-0 font-bold">
          Not authorized to access this route...logging out now.
        </p>

        {/* <Button label="Go back" severity="danger" className='bg-danger my-2' onClick={handleClick} /> */}
      </Card>
    </div>
  );
};

export default Unauthorized;
