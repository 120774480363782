import { useState, useEffect } from "react";
import "./admin.css";
import naija from "../assets/images/ci.svg";
import authFetch from "../axios";
import PublicChart from "../components/HospitalChart/Public";
import PrivateChart from "../components/HospitalChart/Private";
import { Dropdown } from "primereact/dropdown";

const PublicHos = () => {
  // eslint-disable-next-line
  const [loading1, setLoading1] = useState();
  const [year, setYear] = useState("2023");
  const [year1, setYear1] = useState("2023");
  const [products, setProducts] = useState({});

  const years = ["2023", "2024"];

  const years1 = ["2023", "2024"];
  // { params: { answer: 42 } }
  useEffect(() => {
    const getStats = async () => {
      const statz = await authFetch.get(
        `${process.env.REACT_APP_API}/hospital/stats/hospital-stats-count`
      );
      console.log(statz.data);
      // setMyStatz(statz.data.mtn);
      setProducts(statz.data);
      setLoading1(false);
    };
    getStats();
  }, []);

  console.log(year);
  return (
    <>
      <div className="flex">
        <div className="w-12 lg:w-5">
          <span className="block  text-sm font-medium"> Public Hospitals</span>
          <div className="grid my-3">
            <div className="col-12 md:col-6 lg:col-4">
              <div
                className=" shadow-2 p-3 border-round text-white"
                style={{ background: "#0E7706" }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block  text-sm font-medium  mb-3">
                      Total Public Hospitals
                    </span>
                    <div className=" font-bold text-md">
                      {products?.totalPublicHospitalCount ?? "0"}
                    </div>
                    <div className="flex mt-2">
                      <i className="pi pi-arrow-up  text-xs font-bold"></i>
                      <span className="text-xs  font-bold">4.6%</span>
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center "
                    style={{ width: "2.8rem", height: "2.8rem" }}
                  >
                    <img src={naija} className="w-full" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div className="bg-white shadow-2 p-3 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block  text-sm font-medium  mb-3">
                      Total Birth Registrations
                    </span>
                    <div className=" font-bold text-md">
                      {products?.totalPublicHospitalBirthCount ?? "0"}
                    </div>
                    <div className="flex mt-2">
                      <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                      <span className="text-xs text-green-500 font-bold">
                        4.6%
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center "
                    style={{ width: "2.8rem", height: "2.8rem" }}
                  >
                    <img src={naija} className="w-full" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div className="bg-white shadow-2 p-3 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block  text-sm font-medium  mb-3">
                      Total Death Registrations
                    </span>
                    <div className=" font-bold text-md">
                      {products?.totalPublicHospitalDeathCount ?? "0"}
                    </div>
                    <div className="flex mt-2">
                      <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                      <span className="text-xs text-green-500 font-bold">
                        4.6%
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center "
                    style={{ width: "2.8rem", height: "2.8rem" }}
                  >
                    <img src={naija} className="w-full" alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* graph */}
            <div className="col-12 py-5 hidden lg:block">
              <div className="surface-card shadow-2 border-round p-3">
                <div className="flex align-items-center justify-content-between mb-3">
                  <div className="text-900 font-medium text-xs">
                    Birth VS Death Registration Statistics
                  </div>
                  <Dropdown
                    onChange={(e) => setYear1(e.value)}
                    options={years1}
                    value={year1}
                    placeholder="Select Year"
                    className="w-10rem"
                  />
                </div>
                {/* <Chart type="bar" data={data}   /> */}
                <PublicChart year={year1} />
              </div>
            </div>
          </div>
        </div>
        <div className="w-2 hidden lg:block">
          <div
            className="flex relative align-items-center justify-content-center mx-3 py-3"
            style={{ minHeight: "84vh" }}
          >
            <div className="border-left-1 surface-border top-0 left-50 absolute h-full"></div>
            {/* <div className="py-2 z-1 surface-0">
                                <span className="text-900 font-medium">OR</span>
                            </div> */}
          </div>
        </div>
        <div className="w-12 lg:w-5 ">
          <span className="block  text-sm font-medium"> Private Hospitals</span>
          <div className="grid my-3">
            <div className="col-12 md:col-6 lg:col-4">
              <div
                className=" shadow-2 p-3 border-round text-white"
                style={{ background: "#0E7706" }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block  text-sm font-medium  mb-3">
                      Total Private Hospitals
                    </span>

                    <div className=" font-bold text-md">
                      {" "}
                      {products?.totalPrivateHospitalCount ?? "0"}
                    </div>
                    <div className="flex mt-2">
                      <i className="pi pi-arrow-up  text-xs font-bold"></i>
                      <span className="text-xs  font-bold">4.6%</span>
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center "
                    style={{ width: "2.8rem", height: "2.8rem" }}
                  >
                    <img src={naija} className="w-full" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div className="bg-white shadow-2 p-3 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block  text-sm font-medium  mb-3">
                      Total Birth Registrations
                    </span>
                    <div className=" font-bold text-md">
                      {products?.totalPrivateHospitalBirthCount ?? "0"}
                    </div>
                    <div className="flex mt-2">
                      <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                      <span className="text-xs text-green-500 font-bold">
                        4.6%
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center "
                    style={{ width: "2.8rem", height: "2.8rem" }}
                  >
                    <img src={naija} className="w-full" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 md:col-6 lg:col-4">
              <div className="bg-white shadow-2 p-3 border-round">
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block  text-sm font-medium  mb-3">
                      Total Death Registrations
                    </span>
                    <div className=" font-bold text-md">
                      {products?.totalPrivateHospitalDeathCount ?? "0"}
                    </div>
                    <div className="flex mt-2">
                      <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                      <span className="text-xs text-green-500 font-bold">
                        4.6%
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center "
                    style={{ width: "2.8rem", height: "2.8rem" }}
                  >
                    <img src={naija} className="w-full" alt="" />
                  </div>
                </div>
              </div>
            </div>

            {/* graph */}
            <div className="col-12 py-5 hidden lg:block">
              <div className="surface-card shadow-2 border-round p-3">
                <div className="flex align-items-center justify-content-between mb-3">
                  <div className="text-900 font-medium text-xs">
                    Birth VS Death Registration Statistics
                  </div>
                  <Dropdown
                    onChange={(e) => setYear(e.value)}
                    options={years}
                    value={year}
                    placeholder="Select Year"
                    className="w-10rem"
                  />
                </div>
                <PrivateChart year={year} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="grid my-3">
         <div className="col-12 md:col-6 lg:col-4">
        <div className=" shadow-2 p-3 border-round text-white" style={{background:'#0E7706'}}>
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block  text-sm font-medium  mb-3">Total Public Hospitals</span>
                        <div className=" font-bold text-xl">12,000,000</div>
                        <div className='flex mt-2'>
                        <i className="pi pi-arrow-up  text-xs font-bold"></i>
                        <span className='text-xs  font-bold'>4.6%</span>
                        </div>
                        
                    </div>
                    <div className="flex align-items-center justify-content-center " style={{ width: '2.8rem', height: '2.8rem' }}>
                       <img src={naija} className='w-full' alt='' />
                        
                    </div>
                </div>
                
            </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
            <div className="bg-white shadow-2 p-3 border-round">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block  text-sm font-medium  mb-3">Total Birth Registrations</span>
                        <div className=" font-bold text-xl">12,000,000</div>
                        <div className='flex mt-2'>
                        <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                        <span className='text-xs text-green-500 font-bold'>4.6%</span>
                        </div>
                        
                    </div>
                    <div className="flex align-items-center justify-content-center " style={{ width: '2.8rem', height: '2.8rem' }}>
                       <img src={naija} className='w-full' alt='' />
                        
                    </div>
                </div>
               
            </div>
        </div>
        <div className="col-12 md:col-6 lg:col-4">
        <div className="bg-white shadow-2 p-3 border-round">
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block  text-sm font-medium  mb-3">Total Death Registrations</span>
                        <div className=" font-bold text-xl">12,000,000</div>
                        <div className='flex mt-2'>
                        <i className="pi pi-arrow-up text-green-500 text-xs font-bold"></i>
                        <span className='text-xs text-green-500 font-bold'>4.6%</span>
                        </div>
                        
                    </div>
                    <div className="flex align-items-center justify-content-center " style={{ width: '2.8rem', height: '2.8rem' }}>
                       <img src={naija} className='w-full' alt='' />
                       
                    </div>
                </div>
                
            </div>
        </div>
      
      
        <div className="col-12   py-4">
                <div className="surface-card shadow-2 border-round p-3">
                    <div className="flex align-items-center justify-content-between mb-3">
                        <div className="text-900 font-medium">Death & Birth Certificate Registrations(Statistics)</div> 
                    </div>
                    <Chart type="bar" data={data}   />
                </div>
            </div>
     <div className="col-12 lg:col-12 mt-2">
                <div className="card border-round shadow-2 p-3 ">
                <div className="mb-3 flex align-items-center justify-content-between p-3">
        <span className="text-xl font-medium text-900">Recent Registrations</span>
       
    </div>
             <DataTable value={products} 
             ref={dt}
                  filters={filters1}
                    loading={loading1}
                    stripedRows
                     responsiveLayout="scroll"
                     header={header1}
                     paginator
                     paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={5} rowsPerPageOptions={[5,10,50]}
                  
                        >
                       
                        <Column field="name" header="Certificate Number"></Column>
                            <Column field="username" header="Name"></Column>
                            <Column field="email" header="Gender"></Column>
                            <Column field="phone" header="Birth Order"></Column>
                            <Column field="name" header="Date of Birth"></Column>
                            <Column field="username" header="Birth place"></Column>
                            <Column field="email" header="LGA birth"></Column>
                            <Column field="email" header="Status"></Column>
                        <Column field="" header="Action" body={statusBodyTemplate2} />
                    </DataTable>
                    <Tooltip target=".export-buttons>button" position="bottom" />
                </div>
            </div>
            
           
</div>  */}
    </>
  );
};

export default PublicHos;
