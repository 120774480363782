import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import {
  ApproveBirthAttestationApi,
  QueryBirthAttestationApi,
  getSingleAttestationReg,
} from "../../redux/apiCalls";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import {
  approvalStatus,
  getApprovalStatusKey,
  getBgcolor,
  reverse_option_value,
} from "../../utils";
import { sexType } from "../../helpers/constants";
import ApprovalActions from "./ApprovalActions";
import RecordDetails from "./recordDetails";
import StatusArticle from "../../components/statusStrip";

const ViewSingleAttestation = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [approveSuccess, setApproveSuccess] = useState(false);
  const [records, setRecords] = useState([]);
  const [color, setColor] = useState("");

  const getSingleRecord = async () => {
    setLoading(true);
    try {
      const response = await getSingleAttestationReg(id);
      setLoading(false);
      setRecords(response.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getSingleRecord();
  }, [id, approveSuccess]);

  useEffect(() => {
    const color = getBgcolor(+records?.approval_status);
    setColor(color);
  }, [records, approveSuccess]);

  if (loading) {
    return (
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    );
  }

  return (
    <div>
      <div>
        <Button
          label="Go back"
          icon="pi pi-arrow-left"
          className="p-button-sm my-2"
          onClick={goBack}
        />
      </div>
      <div className="flex flex-column gap-5">
        <StatusArticle
          color={color}
          status={getApprovalStatusKey(+records.approval_status)}
        />

        <RecordDetails
          records={records}
          fieldsToDisplay={[
            "registration_center",
            "registration_state",
            "registration_lga",
            "attestation_Number",
          ]}
          fieldProcessors={{
            registration_center: (records) =>
              `${records?.registration_centerData?.Reg_Center_Name ?? "----"}`,
            registration_state: (records) =>
              `${
                records?.registration_centerData?.LGAs?.states?.State_Name ??
                "----"
              }`,
            registration_lga: (records) =>
              `${records?.registration_centerData?.LGAs?.LGA_Name ?? "----"}`,
            attestation_Number: (records) =>
              `${records?.attestation_number ?? "----"}`,
          }}
        />

        <RecordDetails
          type="Applicant"
          records={records}
          fieldsToDisplay={[
            "surname",
            "first_name",
            "middle_name",
            "gender",
            "date_of_birth",
            "country_of_birth",
            "state_of_birth",
            "lga_of_birth",
            "face",
          ]}
          fieldProcessors={{
            surname: (records) => `${records?.personData?.surname ?? "----"}`,
            first_name: (records) =>
              `${records?.personData?.firstname ?? "----"}`,
            middle_name: (records) =>
              `${records?.personData?.middle_name ?? "----"}`,
            date_of_birth: (records) =>
              `${records?.personData?.birth_date ?? "----"}`,
            gender: (records) => {
              return reverse_option_value(records?.personData?.gender, sexType);
            },
            country_of_birth: (records) =>
              `${
                records?.personData?.birth_countryData?.Country_Name ?? "----"
              }`,
            state_of_birth: (records) =>
              `${records?.personData?.birth_stateData?.State_Name ?? "----"}`,
            lga_of_birth: (records) =>
              `${records?.personData?.birth_lgaData?.LGA_Name ?? "----"}`,
            face: (records) => {
              return (
                <>
                  {records?.face ? (
                    <Image
                      src={records?.face}
                      zoomSrc={records?.face}
                      alt={records.id}
                      preview
                      width="100%"
                      height="150px"
                    />
                  ) : (
                    "----"
                  )}
                </>
              );
            },
          }}
        />

        <RecordDetails
          type="Mother"
          records={records}
          fieldsToDisplay={[
            "mother_surname",
            "mother_firstname",
            "mother_maiden_name",
            "state",
            "lga",
            "town",
          ]}
          fieldProcessors={{
            gender: (records) => {
              return reverse_option_value(records?.gender_Gender_ID, sexType);
            },
            town: (records) => `${records?.mother_town ?? "----"}`,
            state: (records) =>
              `${records?.mother_stateData?.State_Name ?? "----"}`,
            lga: (records) => `${records?.mother_lgaData?.LGA_Name ?? "----"}`,
          }}
        />

        <RecordDetails
          type="Father"
          records={records}
          fieldsToDisplay={[
            "father_surname",
            "father_firstname",
            "state",
            "lga",
            "town",
          ]}
          fieldProcessors={{
            gender: (records) => {
              return reverse_option_value(records?.gender_Gender_ID, sexType);
            },
            town: (records) => `${records?.father_town ?? "----"}`,
            state: (records) =>
              `${records?.father_stateData?.State_Name ?? "----"}`,
            lga: (records) => `${records?.father_lgaData?.LGA_Name ?? "----"}`,
          }}
        />

        <article className="shadow-lg bg-white  text-sm px-4 py-2 box-shadow-css">
          <h2 className="text-sm text-black-alpha-90 font-bold">
            <span className="border-bottom-2 border-blue-600">
              Other Particulars{" "}
            </span>{" "}
          </h2>
          <section style={{ wordBreak: "break-word" }}>
            <div className="grid py-3">
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span className="">Request Reason</span>
                <span className="font-semibold uppercase">
                  {records?.request_reasonData?.description ?? "----"}
                </span>
              </div>{" "}
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span className="">Request Required For</span>
                <span className="font-semibold uppercase">
                  {records?.request_required_for ?? "----"}
                </span>
              </div>{" "}
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span className="">Requester's Name</span>
                <span className="font-semibold uppercase">
                  {records?.requester_name ?? "----"}
                </span>
              </div>{" "}
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span className="">Requester's Address</span>
                <span className="font-semibold uppercase">
                  {records?.requester_address ?? "----"}
                </span>
              </div>{" "}
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span className="">Applicant's Phone</span>
                <span className="font-semibold uppercase">
                  {records?.applicant_phone ?? "----"}
                </span>
              </div>
              <div className="flex flex-column gap-2 col-3 py-3 text-xs">
                <span className="">Applicant's Email</span>
                <span className="font-semibold uppercase">
                  {records?.applicant_email ?? "----"}
                </span>
              </div>
            </div>
          </section>
        </article>

        <article className="shadow-lg bg-white  text-sm px-4 py-2 box-shadow-css">
          <h2 className="text-sm text-black-alpha-90 font-bold">
            <span className="border-bottom-2 border-blue-600">Uploaded </span>{" "}
            Documents
          </h2>
          <section
            className="flex flex-wrap gap-3 w-full"
            style={{ wordBreak: "break-word" }}
          >
            {records?.SupportingDocument?.length > 0 ? (
              records.SupportingDocument?.filter(
                (doc) => doc.file !== null
              ).map((filteredDoc) => (
                <div
                  style={{ maxWidth: "30%" }}
                  className="flex flex-wrap gap-3 w-full"
                  key={filteredDoc.type}
                >
                  {filteredDoc.mimeType ? (
                    <>
                      {filteredDoc.mimeType === "application/pdf" ? (
                        <div className="card shadow-lg h-[200px] flex items-center px-2 justify-center rounded-md overflow-hidden">
                          <div className="gap-3 block ">
                            <a
                              className="text-xs"
                              href={filteredDoc.file}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="pi pi-file-pdf h-20 text-center w-full w-2 text-red-700" />
                              {filteredDoc.type?.replace("-", " ")}
                            </a>
                          </div>
                        </div>
                      ) : (
                        <div className="card shadow-lg h-[200px] rounded-md overflow-hidden">
                          <p className="capitalize">
                            <span className="font-bold">Document Type: </span>{" "}
                            {filteredDoc.type?.replace("-", " ")}
                          </p>
                          <Image
                            src={filteredDoc.file}
                            zoomSrc={filteredDoc.file}
                            alt={filteredDoc.type}
                            preview
                            width="250"
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div
                        style={{ maxWidth: "30%" }}
                        className="shadow-lg rounded-md overflow-hidden"
                      >
                        <p className="w-full capitalize">
                          <span className="font-bold">Document Type: </span>{" "}
                          {filteredDoc.type?.replace("-", " ")}
                        </p>
                        <Image
                          src={filteredDoc.file}
                          zoomSrc={filteredDoc.file}
                          alt={filteredDoc.type}
                          preview
                          width="100%"
                          height="300px"
                        />
                        z
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p>No supporting documents available.</p>
            )}
          </section>
        </article>
      </div>
      <ApprovalActions
        id={id}
        records={records}
        setApproveSuccess={setApproveSuccess}
        approveSuccess={approveSuccess}
        approveApi={ApproveBirthAttestationApi}
        queryApi={QueryBirthAttestationApi}
        ApprovalStatus={+records?.approval_status}
        approvalStatusType={approvalStatus}
      />
    </div>
  );
};

export default ViewSingleAttestation;
