import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Roles } from "../helpers/constants";
import { ProgressSpinner } from "primereact/progressspinner";

const StartingPage = () => {
  const navigate = useNavigate();
  const { userInfo: user } = useSelector((state) => state.user);

  useEffect(() => {
    if (user.Role_ID === Roles.TIU.toString()) {
      navigate("/birth/all-registrations");
    } else {
      navigate("/dashboard");
    }

    /* eslint-disable-next-line */
  }, [user]);
  return (
    <>
      <div className="flex justify-content-center align-items-center">
        <ProgressSpinner style={{ width: "3rem", height: "3rem" }} />
      </div>
    </>
  );
};

export default StartingPage;
