import axios from "axios";
import { isArray } from "lodash";
import toast from "react-hot-toast";

function getErrorMessage(data) {
  if (isArray(data?.message)) {
    const numberedMessages = data.message.map(
      (msg, index) => `${index + 1}. ${msg}`
    );
    return toast.error(numberedMessages.join("\n\n"));
  }
  return toast.error(data.message);
}

class ErrorMessageExtractor {
  static errorParser(error) {
    console.log(error);
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.data) {
          return getErrorMessage(error.response.data);
        }
      } else {
        return toast.error(error.message);
      }
    } else {
      return toast.error(error);
    }
  }
}

export default ErrorMessageExtractor;
