import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  changeUserStatus,
  getAllStates,
  getAllUsersApi,
  getCenterByLgaId,
  getLgaByStateId,
  resetUsersPassword,
  unlinkUserAccount,
} from "../../redux/apiCalls";
import { Paginator } from "primereact/paginator";
import { Dropdown } from "primereact/dropdown";
import { roleOptions, Roles } from "../../helpers/constants";
import Swal from "sweetalert2";
import { SplitButton } from "primereact/splitbutton";
import { Toast } from "primereact/toast";
import { useDebounce } from "../../helpers/hooks/useDebounce";
import { useSelector } from "react-redux";

const UserAccess = () => {
  const dataTableRef = useRef(null);
  const { userInfo: user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(20);
  const [role, setRole] = useState(null);
  const [pagination, setPagination] = useState([]);
  const [records, setRecords] = useState([]);
  const [states, setStates] = useState([]);
  const [lgas, setLgas] = useState([]);
  const [centers, setCenters] = useState([]);
  const [selectedState, setSelectedState] = useState(
    +user.Role_ID === Roles.SubAdmin && user.State_ID ? user.State_ID : null
  );
  const [selectedLga, setSelectedLga] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState(null);

  const { value: debouncedValue } = useDebounce(search, 800);
  let pageIndexOffset = 0;
  const pageStartIndex = page > 1 ? (page - 1) * rows : 0;
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1); // PrimeReact uses 0-based page index
    setRows(event.rows);
    pageIndexOffset = event.first;
  };

  const roleFilterOptions = roleOptions.slice();
  roleFilterOptions.unshift({ name: "All", id: "" });

  const filteredRoleOptions =
    +user?.Role_ID === Roles.SubAdmin && user.State_ID
      ? roleFilterOptions.filter(
          (role) =>
            role.id !== Roles.Admin &&
            role.id !== Roles.SubAdmin &&
            role.id !== Roles.TIU
        )
      : roleFilterOptions;

  const statesList = async () => {
    try {
      const data = await getAllStates();
      setStates(data);
    } catch (error) {
      console.log(error);
    }
  };
  const LgaList = async (payload) => {
    try {
      const data = await getLgaByStateId(payload);
      setLgas(data);
    } catch (error) {
      console.log(error);
    }
  };
  const CenterList = async (payload) => {
    try {
      const data = await getCenterByLgaId(payload);
      setCenters(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUsers = async () => {
    setLoading(true);
    try {
      const data = await getAllUsersApi({
        result_per_page: rows,
        search: debouncedValue,
        page,
        role_id: role,
        state_id: selectedState,
        lga_id: selectedLga,
        regcenter_id: selectedCenter,
      });
      setRecords(data.result);
      setPagination(data.pagination);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, [
    rows,
    debouncedValue,
    page,
    role,
    selectedState,
    selectedLga,
    selectedCenter,
    reload,
  ]);

  useEffect(() => {
    statesList();
  }, []);

  useEffect(() => {
    if (selectedState) {
      LgaList(selectedState);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedLga) {
      CenterList(selectedLga);
    }
  }, [selectedLga]);

  const StatusBodyTemplate8 = (rowData) => {
    const [resetLoading, setResetLoading] = useState(false);
    const handleReset = async (id) => {
      setResetLoading(true);
      try {
        const response = await resetUsersPassword(id);
        setResetLoading(false);
        if (response.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `${response.message}, check your email`,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `${response.message}.`,
          });
        }
      } catch (error) {
        setResetLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${error.response?.data.message}`,
        });
      }
    };
    const handleActivate = async (id, st) => {
      setResetLoading(true);
      let status = "";
      if (st === 1) {
        status = "Inactive";
      } else {
        status = "Active";
      }

      try {
        const response = await changeUserStatus({ id, status });
        console.log(response);
        setResetLoading(false);
        if (response.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `${response.message}.`,
          });
          setReload(true);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `${response.message}.`,
          });
        }
      } catch (error) {
        setResetLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${error.response?.data.message}`,
        });
      }
    };
    const handleUnlink = async (id) => {
      setResetLoading(true);
      try {
        const response = await unlinkUserAccount(id);
        setResetLoading(false);
        if (response.success === true) {
          toast.current.show({
            severity: "success",
            summary: "Success",
            detail: `${response.message}`,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: `${response.message}.`,
          });
        }
      } catch (error) {
        setResetLoading(false);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: `${error.response.data.message}`,
        });
      }
    };
    const items = [
      {
        label: "Reset Password",
        command: () => {
          Swal.fire({
            title: "Confirm",
            text: `Are you sure you want to proceed?`,
            showCancelButton: true,
            confirmButtonColor: "#0b6916",
            confirmButtonText: "Proceed",
          }).then((result) => {
            if (result.value) {
              handleReset(rowData.User_ID);
            }
          });
        },
      },
      {
        label:
          rowData.is_active === 1
            ? "Deactivate User"
            : rowData.is_active === 0
            ? "Activate User"
            : "",
        command: () => {
          Swal.fire({
            title: "Confirm",
            text: `Are you sure you want to proceed?`,
            showCancelButton: true,
            confirmButtonColor: "#0b6916",
            confirmButtonText: "Proceed",
          }).then((result) => {
            if (result.value) {
              handleActivate(rowData.User_ID, rowData.is_active);
            }
          });
        },
      },
      {
        label: "Unlink User",
        command: () => {
          Swal.fire({
            title: "Confirm",
            text: `Are you sure you want to proceed?`,
            showCancelButton: true,
            confirmButtonColor: "#0b6916",
            confirmButtonText: "Proceed",
          }).then((result) => {
            if (result.value) {
              handleUnlink(rowData.User_ID);
            }
          });
        },
      },
      {
        label: "Update User",
        command: () => {
          navigate(`update-user/${rowData.User_ID}`);
        },
      },
      {
        label: "View User",
        command: () => {
          let url = "view?";
          if (rowData.State_ID) {
            url += `state=${rowData.State_ID}&`;
          }

          if (rowData.LGA_ID) {
            url += `lga=${rowData.LGA_ID}&`;
          }

          if (rowData.Reg_Center_ID) {
            url += `center=${rowData.Reg_Center_ID}&`;
          }

          url += `id=${rowData.User_ID}`;
          navigate(url);
        },
      },
    ];
    return (
      <SplitButton
        loading={resetLoading}
        label="Actions"
        className="p-button-sm"
        model={items}
      />
    );
  };

  const renderHeader1 = () => {
    return (
      <div className=" hidden md:flex flex-wrap gap-2 justify-content-between">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear"
          className="p-button-outlined"
          onClick={() => {
            setSearch("");
            setSelectedCenter("");
            setSelectedLga("");
            setSelectedState(
              +user.Role_ID === Roles.SubAdmin && user.State_ID
                ? user.State_ID
                : null
            );
            setRole("");
          }}
        />
        <Dropdown
          value={role}
          options={filteredRoleOptions}
          onChange={(e) => {
            setRole(e.target.value);
          }}
          showClear={role}
          placeholder="Filter By Role"
          optionLabel="name"
          optionValue="id"
          className="text-xs"
        />
        {!(+user.Role_ID === Roles.SubAdmin && user.State_ID) ? (
          <Dropdown
            value={selectedState}
            options={states}
            filter
            showClear={!!selectedState}
            onChange={(e) => setSelectedState(e.value)}
            placeholder="Filter State"
            optionLabel="State_Name"
            optionValue="State_ID"
            className="text-xs capitalize"
          />
        ) : null}
        <Dropdown
          value={selectedLga}
          options={lgas}
          filter
          showClear={selectedLga}
          onChange={(e) => setSelectedLga(e.value)}
          placeholder="Filter LGA"
          optionLabel="LGA_Name"
          optionValue="LGA_ID"
          className="text-xs"
        />
        <Dropdown
          value={selectedCenter}
          options={centers}
          filter
          showClear={selectedCenter}
          onChange={(e) => setSelectedCenter(e.value)}
          placeholder="Filter Reg. Center"
          optionLabel="Reg_Center_Name"
          optionValue="Reg_Center_id"
          className="text-xs"
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Keyword Search"
          />
        </span>
      </div>
    );
  };
  const header1 = renderHeader1();

  const SN = (rowData) => {
    const sn = records.findIndex((items) => items === rowData);
    return <p>{pageStartIndex + sn + 1 + pageIndexOffset}</p>;
  };

  const stateTemplate = (rowData) => {
    return <p>{rowData.State?.State_Name ?? ""}</p>;
  };
  const lgaTemplate = (rowData) => {
    return <p className={``}>{rowData.LGAs?.LGA_Name ?? ""}</p>;
  };

  const CenterTemplate = (rowData) => {
    return (
      <p className={``}>
        {rowData.Registration_Centres?.Reg_Center_Name ?? ""}
      </p>
    );
  };
  const RoleTemplate = (rowData) => {
    return <p>{rowData.roles?.name ?? ""}</p>;
  };
  const StatusTemplate = (rowData) => {
    let status;
    let color;
    switch (rowData?.is_active) {
      case 0:
        status = "Inactive";
        color = "btn btn-primary text-red-500 font-bold";
        break;
      case 1:
        status = "Active";
        color = "btn btn-primary text-primary font-bold";
        break;
      default:
        status = "Unknown";
    }
    return <p className={`${color}`}>{status}</p>;
  };
  const emailTemplate = (rowData) => {
    return (
      <div className="flex flex-column justify-content-between">
        <span>{rowData.Email ?? ""}</span>
        <span>{rowData.Phone_No ?? ""}</span>
      </div>
    );
  };
  return (
    <>
      <div className="mb-3 flex align-items-center justify-content-between p-3">
        <span className="text-xl font-medium text-900">Users</span>
        <div className="flex align-items-center export-buttons">
          <Link to={"create"}>
            <Button
              type="button"
              icon="pi pi-user"
              label="Create User"
              className="p-button-success mr-2"
            />
          </Link>
        </div>
      </div>
      <div className="max-w-full overflow-y-auto">
        <DataTable
          value={records}
          ref={dataTableRef}
          loading={loading}
          stripedRows
          responsiveLayout="stack"
          header={header1}
        >
          <Column body={SN} header="S/N"></Column>
          <Column field="UserName" header="Name"></Column>
          <Column
            field="Email"
            header="Email / Phone No"
            body={emailTemplate}
          ></Column>
          <Column field="" header="State" body={stateTemplate} />
          <Column field="" header="LGA" body={lgaTemplate} />
          <Column field="" header="Center" body={CenterTemplate} />
          <Column field="" header="Role" body={RoleTemplate} />
          <Column field="" header="Status" body={StatusTemplate}></Column>
          <Column field="User_ID" header="Id"></Column>
          <Column field="" header="" body={StatusBodyTemplate8} />
        </DataTable>
      </div>

      <Paginator
        first={first}
        rows={rows}
        totalRecords={pagination?.total}
        rowsPerPageOptions={[10, 20, 50, 100]}
        onPageChange={onPageChange}
      ></Paginator>
      <Toast ref={toast} position="top-center" />
    </>
  );
};

export default UserAccess;
