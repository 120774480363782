import _ from "lodash";
import { CREATE_USER_ERROR_MESSAGES } from "../helpers/constants";

const FormLabel = (props) => {
  return (
    <div className="flex gap-3 mb-2">
      <label className="flex gap-1" htmlFor={props.type}>
        {props.label}
        {props.required && <small className="text-red-500">*</small>}
      </label>
      {_.has(props.errors, props.type) ? (
        <small className=" text-red-600">
          {CREATE_USER_ERROR_MESSAGES[props.type]}
        </small>
      ) : null}
    </div>
  );
};

export default FormLabel;
